import React, { useRef, useEffect, useCallback, useLayoutEffect } from 'react';

import { mosaiqStyled } from '../../lib';

import { Typography } from '../Typography';

import { Label } from '../Label';

import { TextareaProps } from './types';

import { TextareaContainer } from './Textarea.styles';

const LabelWrapper = ({ hasLabel, children }: { hasLabel: boolean; children: React.ReactNode }) =>
  hasLabel ? <label>{children}</label> : <>{children}</>;

const Textarea = mosaiqStyled<TextareaProps>(
  ({
    innerRef,
    autoSize = false,
    autoFocus = false,
    resize = false,
    label,
    helperText,
    textareaProps = {},
    disabled = false,
    error = false,
    required = false,
    borderless = false,
    width,
    ...props
  }) => {
    const containerRef = useRef<HTMLDivElement | null>(null);

    const { onChange, ...restTextareaProps } = textareaProps;

    const handleResize = useCallback(() => {
      const textareaEl = containerRef.current?.querySelector('textarea');

      if (!textareaEl) {
        return;
      }

      textareaEl.style.height = '1px';
      textareaEl.style.height = 2 + textareaEl.scrollHeight + 'px';
    }, [containerRef.current]);

    useEffect(() => {
      const textareaEl = containerRef.current?.querySelector('textarea');
      if (autoFocus && textareaEl) {
        textareaEl.setSelectionRange(textareaEl.value?.length, textareaEl.value?.length);
        textareaEl.focus?.();
      }
    }, [autoFocus]);

    useLayoutEffect(() => {
      if (autoSize) {
        handleResize();
      }
    }, [textareaProps?.value]);

    return (
      <TextareaContainer
        ref={(node) => {
          containerRef.current = node;

          if (!!innerRef && 'current' in innerRef) {
            innerRef.current = node;
          }
        }}
        disabled={disabled}
        resize={resize}
        error={error}
        borderless={borderless}
        width={width}
        {...props}
      >
        <LabelWrapper hasLabel={!!label}>
          {!!label && (
            <Label marginBottom={0.5} color={disabled ? 'placeholder' : 'body'}>
              {label}
              {required && <>*</>}
            </Label>
          )}
          <textarea
            data-testid="textArea"
            disabled={disabled}
            onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
              onChange?.(e);
              if (autoSize) {
                handleResize();
              }
            }}
            {...restTextareaProps}
          />
        </LabelWrapper>
        {!!helperText && (
          <Typography
            marginTop={0.5}
            variant="body4"
            color={error ? 'error' : disabled ? 'placeholder' : 'passive'}
          >
            {helperText}
          </Typography>
        )}
      </TextareaContainer>
    );
  },
);

Textarea.displayName = 'Textarea';

export default Textarea;
