/**
 * These are size constants associated with client elements
 */
const dimensions = {
  entityPanelWidth: 353,
  maxReadingWidth: 800,
  maxContainerWidth: 1600,
  minModalWidth: 580,
  maxModalWidth: 900,
} as const;

export default dimensions;
