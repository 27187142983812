interface IBrowserInfo {
  name: string;
  version: string;
}

export function getBrowserInfo(): IBrowserInfo | Error {
  if (typeof window === "undefined") {
    return new Error("window is undefined");
  }

  const ua = window.navigator.userAgent.toLowerCase();

  const regexes: { [key: string]: RegExp } = {
    chrome: /chrome\/([\d.]+)/,
    safari: /version\/([\d.]+).*safari/,
    firefox: /firefox\/([\d.]+)/,
    edge: /edge\/([\d.]+)/,
    opera: /opr\/([\d.]+)/,
  };

  for (const [name, regex] of Object.entries(regexes)) {
    const match = ua.match(regex);

    if (match) {
      return { name, version: match[1] as string };
    }
  }

  return new Error("Browser not detected");
}
