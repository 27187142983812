import styled, { css } from 'styled-components';

import { ControlProps } from './types';

export const ControlInput = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  min-width: ${({ theme }) => theme.spacing(2)}px;
  min-height: ${({ theme }) => theme.spacing(2)}px;
  max-width: ${({ theme }) => theme.spacing(2)}px;
  max-height: ${({ theme }) => theme.spacing(2)}px;
  border-radius: ${({ theme }) => theme.spacing(0.5)}px;
  border: 1px solid ${({ theme }) => theme.mosaiq.colors.grey60};

  svg {
    color: ${({ theme }) => theme.mosaiq.colors.white};
    min-width: ${({ theme }) => theme.spacing(1.5)}px;
    min-height: ${({ theme }) => theme.spacing(1.5)}px;
    max-width: ${({ theme }) => theme.spacing(1.5)}px;
    max-height: ${({ theme }) => theme.spacing(1.5)}px;
  }
`;

export const ControlContainer = styled.label<
  ControlProps & {
    hasLabel: boolean;
  }
>`
  display: inline-flex;
  vertical-align: middle;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;
  user-select: none;

  input {
    opacity: 0;
    width: 0;
    height: 0;
    position: absolute;
  }

  ${({ hasLabel, theme, disabled, highlight }) =>
    hasLabel &&
    css`
      padding: ${theme.spacing(1)}px;
      border-radius: ${theme.spacing(1)}px;

      ${highlight &&
      css`
        background-color: ${theme.mosaiq.colors.grey};
      `}

      ${!disabled &&
      css`
        &:hover {
          background-color: ${theme.mosaiq.colors.grey};
        }
      `}
    `}
`;
