import styled from 'styled-components';

import { Button, ButtonSize, borderSizeMap } from '../Button';
import { IconButton } from '../IconButton';

export const SegmentedButtonWrapper = styled.div<{ size?: ButtonSize }>`
  display: inline-flex;
  gap: 2px;

  & > *:first-child ${Button}, & > ${Button}:first-child {
    ${({ theme, size }) =>
      theme.mosaiq.mixins.squircle({
        corners: ['top-left', 'bottom-left'],
        borderRadius: theme.mosaiq.spacing(borderSizeMap[size ?? 'regular'] ?? 1),
      })};
  }

  &
    > *:not(:first-child):not(:last-child)
    ${Button},
    &
    > ${Button}:not(:first-child):not(:last-child),
  &
    > *:not(:first-child):not(:last-child)
    ${IconButton},
    &
    > ${IconButton}:not(:first-child):not(:last-child) {
    ${({ theme, size }) =>
      theme.mosaiq.mixins.squircle({
        corners: [],
        borderRadius: theme.mosaiq.spacing(borderSizeMap[size ?? 'regular'] ?? 1),
      })};
  }

  &
    > *:last-child
    ${Button},
    &
    > *:last-child
    ${IconButton},
    &
    > ${Button}:last-child,
    &
    > ${IconButton}:last-child {
    ${({ theme, size }) =>
      theme.mosaiq.mixins.squircle({
        corners: ['top-right', 'bottom-right'],
        borderRadius: theme.mosaiq.spacing(borderSizeMap[size ?? 'regular'] ?? 1),
      })};
  }

  &
    > *:only-child
    ${Button},
    &
    > *:only-child
    ${IconButton},
    &
    > ${Button}:only-child,
    &
    > ${IconButton}:only-child {
    ${({ theme, size }) =>
      theme.mosaiq.mixins.squircle({
        corners: ['top-right', 'bottom-right', 'top-left', 'bottom-left'],
        borderRadius: theme.mosaiq.spacing(borderSizeMap[size ?? 'regular'] ?? 1),
      })};
  }
`;
