import React, {
  useRef,
  useState,
  useMemo,
  useLayoutEffect,
  ReactNode,
  MutableRefObject,
  useEffect,
} from 'react';

import { mosaiqStyled } from '../../lib';

import { DropdownArrow } from '../../base';
import { IconButton } from '../IconButton';
import { MenuItem } from '../MenuItem';
import { OverflowMenu } from '../OverflowMenu';
import { Typography, TypographyProps } from '../Typography';

import { TabsProps } from './types';

import { TabsHeaderItem, TabsHeader, TabsHeaderItemMeta, TabsHeaderAdornment } from './Tabs.styles';

type Header = {
  key?: string;
  label: ReactNode;
  meta?: string | number;
  disabled: boolean;
  index: number;
  alt?: string;
} & Omit<TypographyProps, 'innerRef'>;

const Tabs = mosaiqStyled<TabsProps, HTMLDivElement>(
  ({
    tabs,
    value,
    onTabChange,
    size = 'regular',
    innerRef,
    startAdornment,
    endAdornment,
    collapsible = false,
    fullWidth = false,
    ...props
  }) => {
    const headerRef = useRef<HTMLDivElement>() as MutableRefObject<HTMLDivElement>;

    const [activeIndex, setActiveIndex] = useState<number>(0);

    const { headers, contents } = useMemo(() => {
      let headers: Header[] = [];
      let contents: ReactNode[] = [];

      tabs.forEach(({ key, header, disabled, meta, content, alt, ...rest }, i) => {
        headers = [
          ...headers,
          {
            key,
            label: header,
            disabled: disabled ?? false,
            meta,
            alt,
            index: i,
            ...rest,
          },
        ];
        contents = [...contents, content];
      });

      return { headers, contents };
    }, [tabs]);

    const activeContent = useMemo(() => contents[activeIndex], [activeIndex, contents]);

    const headerVariant = useMemo(() => {
      switch (size) {
        case 'xlarge':
          return 'title3';

        case 'large':
          return 'title6';

        case 'small':
          return 'body4';

        default:
          return 'body3';
      }
    }, [size]);

    useEffect(() => {
      if (typeof value !== 'number') {
        return;
      }

      setActiveIndex(value);
    }, [value]);

    const [overflowItems, setOverflowItems] = useState<Header[]>([]);

    useLayoutEffect(() => {
      if (!collapsible || !('IntersectionObserver' in window)) return;

      const handleIntersection = (entries: IntersectionObserverEntry[]) => {
        const nowVisibleIds: number[] = [];
        const nowHiddenIds: number[] = [];

        entries.forEach((entry) => {
          // @ts-ignore
          const tabId: string = entry.target.dataset.tabid;
          if (entry.intersectionRatio < 1) {
            entry.target.classList.add('hidden');
            nowHiddenIds.push(+tabId);
          } else {
            entry.target.classList.remove('hidden');
            nowVisibleIds.push(+tabId);
          }
        });

        setOverflowItems((overflows) => {
          const currentlyHiddenIds = new Set([...overflows.map(({ index }) => index)]);
          nowVisibleIds.forEach((id) => currentlyHiddenIds.delete(id));
          nowHiddenIds.forEach((id) => currentlyHiddenIds.add(id));

          return headers.filter(
            ({ index, alt, label }) =>
              currentlyHiddenIds.has(index) && (!!alt || typeof label === 'string'),
          );
        });
      };

      const observer = new IntersectionObserver(handleIntersection, {
        threshold: [0.1, 1.0],
        root: headerRef.current,
      });

      Array.from(headerRef.current?.children ?? []).forEach((item) => {
        // @ts-ignore
        if (item.dataset.tabid) {
          observer.observe(item);
        }
      });

      return () => observer.disconnect();
    }, [collapsible, headers]);

    const isCollapsed = collapsible && !!overflowItems.length;
    return (
      <div ref={innerRef} {...props}>
        <TabsHeader ref={headerRef} size={size} collapsible={collapsible} isCollapsed={isCollapsed}>
          {!!startAdornment && (
            <TabsHeaderAdornment direction="start">{startAdornment}</TabsHeaderAdornment>
          )}
          {headers.map(({ key, label, meta, disabled, index, ...rest }, i) => (
            <Typography
              key={i}
              fontWeight={size !== 'xlarge' ? 500 : 400}
              variant={headerVariant}
              ellipsis={{ lines: 1 }}
              data-testid="tabHeader"
              data-tabid={index}
              style={{ flex: fullWidth ? 1 : 0 }}
              {...rest}
            >
              <TabsHeaderItem
                size={size}
                disabled={disabled}
                active={activeIndex === i}
                data-testid={key ? `tab-${key}` : undefined}
                fullWidth={fullWidth}
                onClick={() => {
                  setActiveIndex(i);
                  onTabChange?.(i);
                }}
              >
                <span>{label}</span>
                {!!meta && <TabsHeaderItemMeta>{meta}</TabsHeaderItemMeta>}
              </TabsHeaderItem>
            </Typography>
          ))}
          {isCollapsed && (
            <OverflowMenu
              opener={({ isOpen }) => (
                <IconButton size="small" variant="secondary" active data-tabid={headers.length}>
                  <DropdownArrow open={isOpen} />
                </IconButton>
              )}
            >
              {overflowItems.map(({ label, alt, index }) => (
                <MenuItem
                  key={index}
                  tabIndex={index}
                  primaryText={alt ?? (label as string)}
                  onClick={() => {
                    setActiveIndex(index);
                    onTabChange?.(index);
                  }}
                />
              ))}
            </OverflowMenu>
          )}
          {!!endAdornment && (
            <TabsHeaderAdornment direction="end">{endAdornment}</TabsHeaderAdornment>
          )}
        </TabsHeader>
        {activeContent}
      </div>
    );
  },
);

Tabs.displayName = 'Tabs';

export default Tabs;
