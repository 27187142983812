import React, { cloneElement, useMemo } from 'react';

import { mosaiqStyled } from '../../lib';

import { Typography, TextVariant, TextColor } from '../Typography';

import { ListItemProps } from './types';

import {
  ListItemContainer,
  ListItemContent,
  ListItemActions,
  ListItemAvatar,
} from './ListItem.styles';

const ListItem = mosaiqStyled<ListItemProps, HTMLDivElement>(
  ({
    avatar,
    primaryText,
    primaryTextProps,
    secondaryText,
    secondaryTextProps,
    tertiaryText,
    tertiaryTextProps,
    primaryAction,
    secondaryAction,
    size = 'regular',
    active = false,
    disabled = false,
    inline = false,
    actionsOnlyVisibleOnHover = false,
    innerRef,
    ...props
  }) => {
    const primaryTypographyProps = useMemo(
      () => ({
        color: 'body' as TextColor,
        variant: (size === 'small' ? 'body3' : 'body2') as TextVariant,
        ellipsis: { lines: 1 },
        ...(primaryTextProps ?? {}),
      }),
      [primaryTextProps, size],
    );

    const secondaryTypographyProps = useMemo(
      () => ({
        color: 'passive' as TextColor,
        variant: 'body4' as TextVariant,
        ellipsis: { lines: 1 },
        ...(secondaryTextProps ?? {}),
      }),
      [secondaryTextProps],
    );

    const tertiaryTextTypographyProps = useMemo(
      () => ({
        color: 'body' as TextColor,
        variant: (size === 'small' ? 'body4' : 'body3') as TextVariant,
        ...(tertiaryTextProps ?? {}),
      }),
      [tertiaryTextProps],
    );

    const avatarSize = useMemo(() => {
      if (!!avatar?.props?.size) {
        return avatar.props.size;
      }

      switch (size) {
        case 'small':
          return 'micro';

        case 'large':
          return 'regular';

        default:
          return 'small';
      }
    }, [size, avatar]);

    return (
      <ListItemContainer
        ref={innerRef}
        hasAvatar={!!avatar}
        size={size}
        active={active}
        disabled={disabled}
        inline={inline}
        actionsOnlyVisibleOnHover={actionsOnlyVisibleOnHover}
        singleLine={!secondaryText}
        {...props}
      >
        {!!avatar && (
          <ListItemAvatar align={!!tertiaryText ? 'baseline' : 'center'}>
            {cloneElement(avatar, {
              size: avatarSize,
            })}
          </ListItemAvatar>
        )}

        {(primaryText || secondaryText) && (
          <ListItemContent>
            {primaryText &&
              (typeof primaryText === 'string' ? (
                <Typography {...primaryTypographyProps}>{primaryText}</Typography>
              ) : (
                primaryText
              ))}
            {secondaryText &&
              (typeof secondaryText === 'string' ? (
                <Typography {...secondaryTypographyProps}>{secondaryText}</Typography>
              ) : (
                secondaryText
              ))}
            {tertiaryText && (
              <Typography {...tertiaryTextTypographyProps}>{tertiaryText}</Typography>
            )}
          </ListItemContent>
        )}

        {(!!primaryAction || !!secondaryAction) && (
          <ListItemActions>
            {secondaryAction}
            {primaryAction}
          </ListItemActions>
        )}
      </ListItemContainer>
    );
  },
);

export default ListItem;
