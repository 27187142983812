import React from 'react';

import { mosaiqStyled } from '../../lib';

import { FlexProps } from './types';
import { FlexContainer } from './Flex.styles';

const NativeFlex = mosaiqStyled<Omit<FlexProps, 'as'> & { isNative: boolean }>(
  ({
    alignItems,
    alignContent,
    children,
    direction,
    gap,
    justify,
    wrap,
    isNative,
    inline,
    flex,
    ...props
  }) => (
    <FlexContainer
      alignItems={alignItems}
      alignContent={alignContent}
      direction={direction}
      gap={gap}
      justify={justify}
      isNative={isNative}
      wrap={wrap}
      inline={inline}
      flex={flex}
      {...props}
    >
      {children}
    </FlexContainer>
  ),
);

NativeFlex.displayName = 'NativeFlex';

export default NativeFlex;
