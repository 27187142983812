import React, { useState, useEffect } from 'react';

import { mosaiqStyled } from '../../lib';

import { Typography } from '../Typography';

import { RadioProps } from './types';

import { RadioContainer, RadioInput, RadioIcon } from './Radio.styles';

const Radio = mosaiqStyled<RadioProps, HTMLLabelElement>(
  ({
    disabled = false,
    onChange,
    label,
    labelProps,
    helperText,
    checked = false,
    innerRef,
    inputProps,
    ...props
  }) => {
    const isControlled = typeof onChange === 'function' && typeof checked !== 'undefined';

    const [isChecked, setIsChecked] = useState<boolean>(checked);

    useEffect(() => {
      setIsChecked(checked);
    }, [checked]);

    return (
      <RadioContainer
        hasLabel={!!label || !!helperText}
        checked={isChecked}
        disabled={disabled}
        ref={innerRef}
        {...props}
      >
        <input
          type="radio"
          disabled={disabled}
          {...inputProps}
          onChange={(e) => {
            if (!isControlled) {
              setIsChecked(e.target.checked);
            }

            onChange?.(e);
            inputProps?.onChange?.(e);
          }}
          checked={isChecked}
        />
        <RadioInput>{isChecked && <RadioIcon />}</RadioInput>
        {!!label && (
          <Typography
            ellipsis={{ lines: 1 }}
            marginLeft={1.5}
            variant="body2"
            color={disabled ? 'placeholder' : 'body'}
            fontWeight={500}
            {...labelProps}
          >
            {label}
          </Typography>
        )}
        {!!helperText && (
          <Typography
            ellipsis={{ lines: 1 }}
            marginLeft={1.5}
            variant="body4"
            color={disabled ? 'placeholder' : 'passive'}
            italic
          >
            {helperText}
          </Typography>
        )}
      </RadioContainer>
    );
  },
);

Radio.displayName = 'Radio';

export default Radio;
