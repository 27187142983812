import appConfig from "./appConfig";
import { userStories, waitlistForm } from "./appCopytext";

export default {
  appConfig,
  appCopies: {
    userStories,
    waitlistForm,
  },
  regex: {
    email:
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  },
};
