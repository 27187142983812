import { useMediaQuery } from "react-responsive";
import { useTheme } from "styled-components";
import { checkScreenSize, ScreenSize } from "@/components/ui";

export const useIsScreenSize = (size: ScreenSize) => {
  const { min, max } = checkScreenSize(size);
  const theme = useTheme();

  return useMediaQuery({
    ...(min ? { minWidth: theme.breakpoints[min] + 1 } : {}),
    ...(max ? { maxWidth: theme.breakpoints[max] } : {}),
  });
};

export const useIsExtraSmallOnly = () =>
  useIsScreenSize({
    max: "extraSmall",
  });

export const useIsSmallOnly = () =>
  useIsScreenSize({
    min: "extraSmall",
    max: "small",
  });

export const useIsMediumOnly = () =>
  useIsScreenSize({
    min: "small",
    max: "medium",
  });

export const useIsLargeOnly = () =>
  useIsScreenSize({
    min: "medium",
    max: "large",
  });

export const useIsExtraLargeOnly = () =>
  useIsScreenSize({
    min: "large",
    max: "extraLarge",
  });

export const useIsExtraExtraLargeOnly = () =>
  useIsScreenSize({
    min: "extraLarge",
  });
