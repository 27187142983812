import AvatarSarah from "@/assets/avatars/sarah.jpeg";
import AvatarJohn from "@/assets/avatars/john.jpeg";
import AvatarDavid from "@/assets/avatars/david.jpeg";
import AvatarMichael from "@/assets/avatars/michael.jpeg";

export const waitlistForm = {
  cta: "Join Waitlist",
  placeholder: "Enter email address to join waitlist",
};

export const userStories = [
  {
    story:
      "Meet Sarah, a loving parent and devoted spouse. Sarah's top priority is ensuring the well-being of her family, both now and in the future. She's proactive about planning and organizing family affairs, from finances to cherished memories.",
    eternal:
      "Sarah uses EternalTrust to securely store important documents like insurance policies, her bank account information and a letter for the family. She designates her spouse and children as nominees, knowing that EternalTrust will provide them this critical information if anything were to happen to her.",
    name: "Sarah Schmidt",
    occupation: "Visual Designer, A Parent",
    avatar: AvatarSarah,
  },
  {
    story:
      "David is a successful business owner and tech enthusiast. He understands the importance of digital security and organization, especially when it comes to managing his assets and personal information.",
    eternal:
      "David utilizes EternalTrust to centralize his digital estate, including passwords, online accounts, and important documents. He designates his trusted friend or family member as a nominee, confident that EternalTrust will ensure peace of mind for his family.",
    name: "David Williams",
    occupation: "Family Business Owner",
    avatar: AvatarDavid,
  },
  {
    story:
      "Michael is a successful professional in his late 30s who values his independence and freedom. While he may not have immediate family of his own, he's close to his siblings and parents.",
    eternal:
      "Michael uses EternalTrust to ensure that his family has access to important information and instructions if anything were to happen to him unexpectedly. He designates his siblings and parents as nominees, providing them with peace of mind and reassurance.",
    name: "Michael Spade",
    occupation: "Bachelor Software Engineer",
    avatar: AvatarMichael,
  },
  {
    story:
      "John is a retiree who adores spending time with his grandchildren. He wants to leave behind more than just material possessions – he wants to pass on his wisdom and love.",
    eternal:
      "John uses EternalTrust to leave a photo, share family stories, and document life lessons for his grandchildren. He designates his children as nominees, ensuring that his legacy of love and guidance will live on for generations to come.",
    name: "John Curtis",
    occupation: "Retired GrandFather",
    avatar: AvatarJohn,
  },
];
