import React, { useEffect, useRef, useState, MutableRefObject, KeyboardEvent } from 'react';

import { mosaiqStyled } from '../../lib';

import { MenuProps } from './types';

import { MenuContainer, MenuCard, MenuShimmer } from './Menu.styles';

const Menu = mosaiqStyled<MenuProps, HTMLDivElement>(
  ({
    children,
    maxHeight = 304,
    maxWidth,
    minWidth,
    innerRef,
    autoFocus = true,
    hideShimmer = false,
    autoFocusItem = -1,
    preventScroll = true,
    ...props
  }) => {
    const cardRef = useRef<HTMLDivElement>(null);

    const menuRef = useRef() as MutableRefObject<HTMLDivElement | null>;

    const activeItemIndex = useRef<number>(autoFocusItem);

    const [showShimmer, setShowShimmer] = useState<boolean>(false);

    const [menuItemsCount, setMenuItemsCount] = useState<number>(0);

    const focusMenuItem = (index: number) =>
      (menuRef.current?.querySelector?.(`[tabindex="${index}"]`) as HTMLElement)?.focus?.({
        preventScroll,
      });

    const handleKeyDown = (e: KeyboardEvent<HTMLDivElement>) => {
      if (!menuItemsCount) {
        return;
      }

      if (e.key === 'ArrowUp') {
        e.preventDefault();
        e.stopPropagation();
        activeItemIndex.current =
          activeItemIndex.current <= 0 ? menuItemsCount - 1 : activeItemIndex.current - 1;
        focusMenuItem(activeItemIndex.current);
        return;
      }

      if (e.key === 'ArrowDown') {
        e.preventDefault();
        e.stopPropagation();
        activeItemIndex.current =
          activeItemIndex.current < 0 || activeItemIndex.current === menuItemsCount - 1
            ? 0
            : activeItemIndex.current + 1;
        focusMenuItem(activeItemIndex.current);
        return;
      }

      if (e.key === 'Enter' && menuRef.current?.contains?.(document?.activeElement)) {
        e.preventDefault();
        e.stopPropagation();
        (document?.activeElement as HTMLElement)?.click?.();
        return;
      }
    };

    useEffect(() => {
      if (!menuRef.current) {
        return;
      }

      setMenuItemsCount(menuRef.current.querySelectorAll('[tabindex]')?.length ?? 0);
    }, [menuRef.current]);

    useEffect(() => {
      if (!cardRef.current) {
        return;
      }

      setShowShimmer(!hideShimmer && cardRef.current?.scrollHeight > cardRef.current?.offsetHeight);
    }, [cardRef.current]);

    useEffect(() => {
      if (autoFocus) {
        menuRef.current?.focus?.({
          preventScroll,
        });
      }
    }, [autoFocus]);

    useEffect(() => {
      if (autoFocusItem >= 0 && !!menuRef.current) {
        focusMenuItem(autoFocusItem);
      }
    }, [autoFocusItem]);

    return (
      <MenuContainer
        maxHeight={maxHeight}
        maxWidth={maxWidth}
        minWidth={minWidth}
        hasShimmer={showShimmer}
        tabIndex={autoFocus ? 0 : -1}
        ref={(node) => {
          menuRef.current = node;

          if (!!innerRef && 'current' in innerRef) {
            innerRef.current = node;
          }
        }}
        onKeyDown={handleKeyDown}
        {...props}
      >
        <MenuCard ref={cardRef}>{children}</MenuCard>
        {!!showShimmer && <MenuShimmer />}
      </MenuContainer>
    );
  },
);

Menu.displayName = 'Menu';

export default Menu;
