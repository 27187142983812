import styled from 'styled-components';

export const ExpanderContent = styled.div`
  overflow-y: hidden;
  max-height: 0;
  transition: max-height 400ms ease-in-out;
`;

export const ExpanderSummary = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing(1)}px;

  list-style: none;
  cursor: pointer;

  ${({ theme }) => theme.mosaiq.typography.body3({ singleLine: true })};

  & > svg {
    opacity: 0.5;
    transition: transform 250ms ease;
    transform: rotate(0deg);
  }
`;

export const ExpanderLabel = styled.span``;

export const ExpanderItem = styled.aside<{ open: boolean }>`
  &[open] ${ExpanderSummary} {
    & ${ExpanderLabel} {
      display: none;
    }

    &::before {
      content: 'Hide';
    }
  }

  &[open] ${ExpanderContent} {
    max-height: 100vh;
  }

  &[open] ${ExpanderSummary} > svg {
    transform: rotate(-180deg);
  }
`;
