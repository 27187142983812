import React from 'react';
import { mosaiqStyled } from '../../lib';

import { ToastWrapper, ToastContentWrapper, ToastContent, ToastCTAs } from './Toast.styles';
import { ToastProps } from './types';

const Toast = mosaiqStyled<ToastProps, HTMLDivElement>(
  ({ icon, message, primaryAction, secondaryAction, innerRef, ...props }) => {
    return (
      <ToastWrapper ref={innerRef} {...props}>
        <ToastContentWrapper>
          {icon}
          <ToastContent $hasIcon={!!icon}>{message}</ToastContent>
          {!!primaryAction || !!secondaryAction ? (
            <ToastCTAs>
              {primaryAction}
              {secondaryAction}
            </ToastCTAs>
          ) : null}
        </ToastContentWrapper>
      </ToastWrapper>
    );
  },
);

Toast.displayName = 'Toast';

export default Toast;
