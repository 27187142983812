import styled from 'styled-components';

export const ListGroupContent = styled.div<{ isOpen: boolean }>`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing(0.5)}px;
  overflow: hidden;
  transition: max-height 0.4s ease-in-out;
  max-height: ${({ isOpen }) => (isOpen ? 100000 : 0)}px;
`;
