import styled, { css } from 'styled-components';

import { ControlContainer } from '../../base/Control';
import { SwitchProps } from './types';

export const SwitchThumb = styled.div`
  height: 10px;
  width: 10px;
  background-color: ${({ theme }) => theme.mosaiq.colors.white};
  border-radius: 50%;
  transform: translateX(0);
  transition: transform 200ms ease;
`;

export const SwitchInput = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  position: relative;
  background-color: ${({ theme }) => theme.mosaiq.colors.grey60};
  border: 1px solid ${({ theme }) => theme.mosaiq.colors.grey60};
  border-radius: ${({ theme }) => theme.spacing(1)}px;
  min-width: ${({ theme }) => theme.spacing(3)}px;
  min-height: ${({ theme }) => theme.spacing(2)}px;
  max-width: ${({ theme }) => theme.spacing(3)}px;
  max-height: ${({ theme }) => theme.spacing(2)}px;
  padding: ${({ theme }) => theme.spacing(0.25)}px;
`;

export const SwitchContainer = styled(ControlContainer)<SwitchProps>`
  ${SwitchInput} {
    ${({ active, disabled, theme }) => {
      if (disabled) {
        return css`
          background-color: ${theme.mosaiq.colors.grey};
          border-color: ${theme.mosaiq.colors.grey60};

          ${SwitchThumb} {
            ${active
              ? css`
                  background-color: ${theme.mosaiq.colors.placeholder};
                  transform: translateX(8px);
                `
              : css`
                  background-color: ${theme.mosaiq.colors.grey60};
                `}
          }
        `;
      }

      if (active) {
        return css`
          background-color: ${theme.mosaiq.colors.black};
          border-color: ${theme.mosaiq.colors.black};

          ${SwitchThumb} {
            transform: translateX(8px);
          }
        `;
      }

      return null;
    }}
  }

  ${({ flex }) =>
    flex &&
    css`
      flex: 1;
      width: 100%;
    `}

  &:hover {
    ${({ active, disabled, theme }) =>
      !disabled &&
      !active &&
      css`
        ${SwitchInput} {
          background-color: ${active
            ? theme.mosaiq.colors.passive
            : theme.mosaiq.colors.placeholder};
          border-color: ${active ? theme.mosaiq.colors.passive : theme.mosaiq.colors.placeholder};
        }
      `};
  }
`;

export const SwitchTextContainer = styled.div<{ inline?: boolean }>`
  display: flex;
  width: 100%;
  align-items: center;
  ${({ inline }) =>
    !inline &&
    css`
      flex-wrap: wrap;
      flex-direction: column;
      align-items: flex-start;
    `};
`;
