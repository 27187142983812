import styled from "styled-components";

export const VerticalTimelineWrapper = styled.div`
  .vertical-timeline {
    width: 100%;
    max-width: 800px;
  }

  .vertical-timeline-element {
    position: relative;
    margin: 8px 0;

    .vertical-timeline-element-content-arrow {
      border: 10px solid transparent;
      border-right: 10px solid ${({ theme }) => theme.mosaiq.colors.white};
    }

    .vertical-timeline-element-content {
      transition: transform 0.2s ease-out;
      border: 0 none;
      box-shadow: none;
      padding: 0;
      background: ${({ theme }) => theme.mosaiq.colors.white};
      color: ${({ theme }) => theme.mosaiq.colors.title};
      padding: 12px;
    }

    .vertical-timeline-element-icon {
      cursor: pointer;
      transition: all 0.2s ease-out;

      background: ${({ theme }) => theme.mosaiq.colors.title};

      width: 16px;
      height: 16px;
      left: 50%;
      margin-left: -9px;
      margin-top: 24px;

      &:hover {
        width: 20px;
        height: 20px;
        margin-left: -12px;
      }
    }
  }

  ${({ theme }) => theme.mosaiq.mediaQueries.screenSize({
    max: "extraSmall",
  })`
    .vertical-timeline {
      margin-left: -16px;
      width: calc(100% + 16px);

      .vertical-timeline-element-content {
        margin-left: 48px;
      }

      .vertical-timeline-element-icon {
        left: 20px;
        margin-top: 19px;
      }

      .vertical-timeline-element-date {
        display: none;
      }
    }
  `}
`;
