import { Carousel } from "@trendyol-js/react-carousel";
import { ArrowLeft, ArrowRight } from "phosphor-react";

import config from "@/common/config";
import ContentBox from "@/components/layout/ContentBox";
import { Box, Flex, IconButton, Typography } from "@/components/ui";
import { useIsExtraSmallOnly } from "@/hooks/useMediaQueries";

import { HomeStoriesAvatar } from "./UserStories.styles";

const UserStories = () => {
  const isMobileView = useIsExtraSmallOnly();

  return (
    <Box backgroundColor="white" padding={isMobileView ? [0, 0, 10] : [10, 0]}>
      <ContentBox maxWidth={isMobileView ? "100%" : 105}>
        <Typography
          variant={isMobileView ? "title2" : "title1"}
          style={{ letterSpacing: -1, lineHeight: "48px" }}
          marginBottom={isMobileView ? 4 : 12}
          textAlign={isMobileView ? "left" : "center"}
        >
          Still not convinced?
        </Typography>
        <div style={{ position: "relative" }}>
          <Carousel
            show={isMobileView ? 1.05 : 1.1}
            slide={1}
            swipeOn={0.5}
            swiping={true}
            useArrowKeys
            responsive
            infinite={false}
            leftArrow={
              <Flex
                alignItems="center"
                justify="center"
                style={{
                  height: "100%",
                  position: "absolute",
                  top: 0,
                  left: -16,
                  zIndex: 2,
                }}
              >
                <IconButton
                  variant="primary"
                  size="large"
                  style={{ borderRadius: "50%" }}
                >
                  <ArrowLeft weight="bold" />
                </IconButton>
              </Flex>
            }
            rightArrow={
              <Flex
                alignItems="center"
                justify="center"
                style={{
                  height: "100%",
                  position: "absolute",
                  top: 0,
                  right: -16,
                }}
              >
                <IconButton
                  variant="primary"
                  size="large"
                  style={{ borderRadius: "50%" }}
                >
                  <ArrowRight weight="bold" />
                </IconButton>
              </Flex>
            }
          >
            {config.appCopies.userStories.map((user) => (
              <Box
                key={user.name}
                backgroundColor={({ theme }) => theme.mosaiq.colors.grey}
                borderRadius={isMobileView ? 4 : 8}
                padding={isMobileView ? 3 : 8}
                marginRight={isMobileView ? 1 : 4}
                minHeight={50}
                style={{ userSelect: "none", height: "100%" }}
              >
                <Flex
                  style={{ height: "100%" }}
                  direction="column"
                  justify="space-between"
                  gap={4}
                >
                  <Flex direction="column" gap={1}>
                    <Typography
                      variant={isMobileView ? "body2" : "title5"}
                      textAlign="left"
                    >
                      {user.story}
                    </Typography>
                    <Typography
                      variant={isMobileView ? "body2" : "title5"}
                      textAlign="left"
                    >
                      {user.eternal}
                    </Typography>
                  </Flex>

                  <Flex alignItems="center" gap={2}>
                    <HomeStoriesAvatar
                      width={isMobileView ? 6 : 12}
                      height={isMobileView ? 6 : 12}
                      border
                      borderColor="#93c956"
                    >
                      <img src={user.avatar} />
                    </HomeStoriesAvatar>
                    <Flex direction="column">
                      <Typography
                        variant={isMobileView ? "title5" : "title4"}
                        fontWeight={400}
                      >
                        {user.name}
                      </Typography>
                      <Typography
                        variant={isMobileView ? "title6" : "title5"}
                        fontWeight={400}
                      >
                        {user.occupation}
                      </Typography>
                    </Flex>
                  </Flex>
                </Flex>
              </Box>
            ))}
          </Carousel>
        </div>
      </ContentBox>
    </Box>
  );
};

export default UserStories;
