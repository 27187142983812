import { Typography } from "@/components/ui";
import styled from "styled-components";

export const HighlightTypography = styled(Typography)`
  .highlight {
    padding: 1px 8px 4px;
    border-radius: 6px;

    &.green {
      background: ${({ theme }) => theme.mosaiq.colors.emerald};
    }

    &.orange {
      background: ${({ theme }) => theme.mosaiq.colors.sandyBrown};
    }

    &.yellow {
      background: ${({ theme }) => theme.mosaiq.colors.navajoWhite};
    }

    &.yellowHard {
      background: #fdff63;
    }

    &.pink {
      background: ${({ theme }) => theme.mosaiq.colors.pink};
    }

    &.lightblue {
      background: ${({ theme }) => theme.mosaiq.colors.portage};
    }

    &.black {
      background: ${({ theme }) => theme.mosaiq.colors.black};
      color: ${({ theme }) => theme.mosaiq.colors.white};
    }
  }
`;
