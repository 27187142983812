import kebabCase from "lodash/kebabCase";
import uniqueId from "lodash/uniqueId";

import breakpoints from "./theme/breakpoints";

export const selfOrFunction = <T, P>(
  props: P,
  t: T
): T extends () => unknown ? ReturnType<T> : T =>
  typeof t === "function" ? t(props) : t;

type Breakpoint = keyof typeof breakpoints;

export type ScreenSize =
  | {
      min: Breakpoint;
      max: Breakpoint;
    }
  | {
      min: Breakpoint;
      max?: never;
    }
  | {
      min?: never;
      max: Breakpoint;
    };

export const checkScreenSize = ({ min, max }: ScreenSize) => {
  // If both props are given
  if (min && max) {
    // Error if they are equal
    if (min === max) {
      throw new Error("Same value is given for both min and max breakpoints");
    }

    // Swap if the smaller one is bigger
    if (breakpoints[min] > breakpoints[max]) {
      return { min: max, max: min };
    }
  }

  return { min, max };
};

// TODO: migrate to React useId hook when upgrading to React 18
export const generateId = (prefix?: string) =>
  uniqueId(kebabCase(prefix).substring(0, 10));
