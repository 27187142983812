import styled, { css } from 'styled-components';
import { ControlContainer, ControlInput } from '../../base/Control';

import { RadioProps } from './types';

export const RadioIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  border-radius: 50%;
`;

export const RadioInput = styled(ControlInput)`
  border-radius: ${({ theme }) => theme.spacing(50)}%;
`;

export const RadioContainer = styled(ControlContainer)<
  RadioProps & {
    hasLabel: boolean;
  }
>`
  ${RadioInput} {
    ${({ checked, disabled, hasLabel, theme }) => {
      if (disabled) {
        if (checked) {
          return css`
            background-color: ${theme.mosaiq.colors.grey60};
            border: ${theme.spacing(0.5)}px solid ${theme.mosaiq.colors.grey};

            svg {
              color: ${({ theme }) => theme.mosaiq.colors.passive};
            }
          `;
        }

        return css`
          background-color: ${theme.mosaiq.colors.grey};
          border: thin solid ${theme.mosaiq.colors.grey40};
        `;
      }

      if (checked) {
        return css`
          border: ${theme.spacing(0.5)}px solid ${theme.mosaiq.colors.black};

          ${!hasLabel &&
          css`
            &:hover {
              background-color: ${theme.mosaiq.colors.white};
              border: ${theme.spacing(0.5)}px solid ${theme.mosaiq.colors.passive};
            }
          `}
        `;
      }

      return css`
        background-color: ${theme.mosaiq.colors.white};
        border: thin solid ${theme.mosaiq.colors.grey60};

        ${!hasLabel &&
        css`
          &:hover {
            border: thin solid ${theme.mosaiq.colors.passive};
          }
        `}
      `;
    }}
  }
`;
