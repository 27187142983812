import React, { useState, useEffect, useRef } from 'react';
import { Check, Minus } from 'phosphor-react';

import { mosaiqStyled } from '../../lib';

import { Typography } from '../Typography';

import { CheckboxProps } from './types';

import { CheckboxContainer, CheckboxInput, CheckboxTextContainer } from './Checkbox.styles';

const Checkbox = mosaiqStyled<CheckboxProps, HTMLDivElement, HTMLInputElement>(
  ({
    disabled = false,
    onChange,
    label,
    labelProps,
    helperText,
    checked = false,
    innerRef,
    inputProps,
    indeterminate = false,
    startAdornment,
    endAdornment,
    inline = true,
    ...props
  }) => {
    const inputRef = useRef<HTMLInputElement>(null);

    const [isChecked, setIsChecked] = useState<boolean>(checked);

    const isControlled = typeof onChange === 'function' && typeof checked !== 'undefined';

    useEffect(() => {
      setIsChecked(checked);
    }, [checked]);

    useEffect(() => {
      if (!inputRef.current) {
        return;
      }

      if (inputRef.current.indeterminate === indeterminate) {
        return;
      }

      inputRef.current.indeterminate = indeterminate;
    }, [indeterminate, inputRef.current]);

    return (
      <CheckboxContainer
        hasLabel={!!label || !!helperText}
        checked={isChecked}
        disabled={disabled}
        indeterminate={!isChecked && indeterminate}
        startAdornment
        {...props}
      >
        <input
          type="checkbox"
          ref={innerRef}
          disabled={disabled}
          {...inputProps}
          onChange={(e) => {
            if (!isControlled) {
              setIsChecked(e.target.checked);
            }

            onChange?.(e);
            inputProps?.onChange?.(e);
          }}
          checked={isChecked}
        />
        <CheckboxInput data-testid="checkboxInput">
          {indeterminate && !isChecked ? (
            <Minus weight="bold" />
          ) : (
            isChecked && <Check weight="bold" />
          )}
        </CheckboxInput>

        {!!startAdornment && startAdornment}

        {(!!label || !!helperText) && (
          <CheckboxTextContainer inline={inline}>
            {!!label && (
              <Typography
                ellipsis={{ lines: 1 }}
                variant="body2"
                color="body"
                fontWeight={500}
                {...labelProps}
              >
                {label}
              </Typography>
            )}

            {!!helperText && typeof helperText === 'string' ? (
              <Typography ellipsis={{ lines: 1 }} variant="body4" color="passive" italic>
                {helperText}
              </Typography>
            ) : (
              helperText
            )}
          </CheckboxTextContainer>
        )}

        {!!endAdornment && endAdornment}
      </CheckboxContainer>
    );
  },
);

Checkbox.displayName = 'Checkbox';

export default Checkbox;
