// @ts-nocheck
import styled, {
  css,
  DefaultTheme,
  FlattenSimpleInterpolation,
} from "styled-components";

import { ButtonProps, ButtonSize, ButtonVariant } from "./types";

export const borderSizeMap = {
  large: 1.25,
  regular: 1,
  small: 0.75,
};

export const buttonBaseStyles = css`
  display: flex;
  align-items: center;
  border: 0;
  cursor: pointer;
  transition: 0.16s background-color linear;

  & > span:empty {
    display: none;
  }

  & > span {
    transition: 0.16s color linear;
  }

  & svg {
    flex-shrink: 0;
  }
`;

export const buttonBorder = ({
  size,
  theme,
  variant,
}: {
  size?: ButtonSize;
  theme: DefaultTheme;
  variant?: ButtonVariant;
}) => {
  if (variant === "outline") {
    return css`
      border-radius: ${theme.mosaiq.spacing(
        borderSizeMap[size ?? "regular"] ?? 1
      )}px;
      border: 1px solid ${theme.mosaiq.colors.grey60};
    `;
  }
  return theme.mosaiq.mixins.squircle({
    // Provide fallback border-radius for browsers without Paint API support
    borderRadius: theme.mosaiq.spacing(borderSizeMap[size ?? "regular"] ?? 1),
  });
};

export const buttonColor = ({
  variant = "primary",
  theme,
  disabled = false,
  active = false,
  selected = false,
  error = false,
}: {
  variant: ButtonVariant;
  theme: DefaultTheme;
  disabled?: boolean;
  active?: boolean;
  selected?: boolean;
  error?: boolean;
}): FlattenSimpleInterpolation => {
  if (error) {
    return css`
      background-color: ${theme.mosaiq.colors.softPeach};

      & > span {
        color: ${theme.mosaiq.colors.error};
      }

      svg {
        color: ${theme.mosaiq.colors.passive};
      }
    `;
  }

  if (variant === "secondary") {
    if (disabled) {
      return css`
        background-color: unset;
        pointer-events: none;

        span,
        svg {
          color: ${theme.mosaiq.colors.placeholder};
        }
      `;
    }

    if (selected) {
      return css`
        background-color: ${theme.mosaiq.colors.grey20};
      `;
    }

    return css`
      background-color: ${active ? theme.mosaiq.colors.grey : "transparent"};

      & > span {
        color: ${theme.mosaiq.colors.title};
      }

      svg {
        color: ${theme.mosaiq.colors.passive};
      }

      &:hover {
        background-color: ${theme.mosaiq.colors.grey20};
      }

      &:active {
        background-color: ${theme.mosaiq.colors.grey60};
      }

      &:focus {
        background-color: ${theme.mosaiq.colors.grey};
      }
    `;
  }

  if (variant === "outline") {
    if (disabled) {
      return css`
        background-color: unset;
        pointer-events: none;

        span,
        svg {
          color: ${theme.mosaiq.colors.placeholder};
        }
      `;
    }
    return css`
      background-color: ${theme.colors.white};

      svg {
        color: ${theme.mosaiq.colors.passive};
      }

      &:hover {
        background-color: ${theme.mosaiq.colors.grey};
      }

      &:active {
        background-color: ${theme.mosaiq.colors.grey20};
      }

      &:focus {
        background-color: ${theme.mosaiq.colors.grey20};
      }
    `;
  }

  if (disabled) {
    return css`
      pointer-events: none;
      background-color: ${theme.mosaiq.colors.grey20};

      & > span {
        color: ${theme.mosaiq.colors.placeholder};
      }

      svg {
        color: ${theme.mosaiq.colors.placeholder};
      }
    `;
  }

  if (selected) {
    return css`
      background-color: ${theme.mosaiq.colors.black};

      & > span {
        color: ${theme.mosaiq.colors.placeholder};
      }

      svg {
        color: ${theme.mosaiq.colors.placeholder};
      }
    `;
  }

  return css`
    background-color: ${theme.mosaiq.colors.black};

    & > span {
      color: ${theme.mosaiq.colors.white};
    }

    svg {
      color: ${theme.mosaiq.colors.grey20};
    }

    &:hover {
      background-color: ${theme.mosaiq.colors.body};

      & > span {
        color: ${theme.mosaiq.colors.grey};
      }

      svg {
        color: ${theme.mosaiq.colors.white};
      }
    }

    &:active {
      background-color: ${theme.mosaiq.colors.black};

      & > span {
        color: ${theme.mosaiq.colors.placeholder};
      }

      svg {
        color: ${theme.mosaiq.colors.placeholder};
      }
    }

    &:focus {
      background-color: ${theme.mosaiq.colors.black};

      & > span {
        color: ${theme.mosaiq.colors.placeholder};
      }

      svg {
        color: ${theme.mosaiq.colors.placeholder};
      }
    }
  `;
};

export const ButtonContainer = styled.button<ButtonProps>`
  width: max-content;

  ${buttonBaseStyles}

  ${buttonColor}

  ${buttonBorder}

  ${({ size = "regular", theme }) => {
    switch (size) {
      case "large":
        return css`
          padding: 0px ${theme.mosaiq.spacing(2)}px;
          min-height: ${theme.mosaiq.spacing(5)}px;
          max-height: ${theme.mosaiq.spacing(5)}px;
          ${theme.mosaiq.typography.body2({ singleLine: true })}
        `;

      case "small":
        return css`
          padding: 0px ${theme.mosaiq.spacing(1)}px;
          min-height: ${theme.mosaiq.spacing(3)}px;
          max-height: ${theme.mosaiq.spacing(3)}px;
          ${theme.mosaiq.typography.body4({ singleLine: true })}
        `;

      default:
        return css`
          padding: 0px ${theme.mosaiq.spacing(1.25)}px;
          min-height: ${theme.mosaiq.spacing(4)}px;
          max-height: ${theme.mosaiq.spacing(4)}px;
          ${theme.mosaiq.typography.body3({ singleLine: true })}
        `;
    }
  }}

  ${({ flex }) =>
    flex &&
    css`
      width: 100%;
      justify-content: center;
    `};

  &&& span {
    ${({ ellipsize }) =>
      ellipsize &&
      css`
        display: initial;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      `};
  }

  & > span {
    display: flex;
    align-items: center;
    font-weight: 500;
    font-family: ${({ theme }) => theme.mosaiq.fonts.main};
    margin-left: ${({ startAdornment, theme, size }): number =>
      !!startAdornment
        ? theme.mosaiq.spacing(size === "small" ? 0.5 : 0.75)
        : 0}px;
    margin-right: ${({ endAdornment, theme, size }): number =>
      !!endAdornment
        ? theme.mosaiq.spacing(size === "small" ? 0.5 : 0.75)
        : 0}px;
    width: max-content;
  }
`;
