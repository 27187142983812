import React, { PropsWithChildren } from 'react';

import { mosaiqStyled } from '../../lib';

import { GridContainer } from './Grid.styles';

export type GridProps = {
  columns?: number;
};

const Grid = mosaiqStyled<PropsWithChildren<GridProps>, HTMLDivElement>(
  ({ columns = 4, children, ...props }) => {
    return (
      <GridContainer {...props} $columns={columns}>
        {children}
      </GridContainer>
    );
  },
);

export default Grid;
