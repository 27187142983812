// @ts-nocheck
import React, {
  useEffect,
  useState,
  Children,
  cloneElement,
  isValidElement,
  PropsWithChildren,
  ReactElement,
} from "react";
import Tippy, { TippyProps } from "@tippyjs/react/headless";
import { Instance } from "tippy.js";

import { mosaiqStyled } from "../../lib";

import { Menu } from "../Menu";

import { OverflowMenuProps } from "./types";

import { OverflowMenuOpener } from "./OverflowMenu.styles";

const OverflowMenu = mosaiqStyled<
  PropsWithChildren<OverflowMenuProps>,
  HTMLDivElement
>(
  ({
    children,
    disabled = false,
    opener,
    openerWidth = "max-content",
    sameWidth = false,
    closeOnSelect = true,
    defaultOpen = false,
    innerRef,
    menuProps = {},
    onClose,
    appendTo,
    placement = "bottom",
    tippyProps = {},
    ignoreOutsideClicksFrom = [],
    maxHeight,
    maxWidth,
    ...props
  }) => {
    const [showMenu, setShowMenu] = useState<boolean>(defaultOpen);

    const handleClose = (_?: Instance, e?: Event): false | void => {
      if (!!ignoreOutsideClicksFrom?.length && e?.target) {
        if (
          ignoreOutsideClicksFrom.some((el) =>
            el?.current?.contains(e.target as Node)
          )
        ) {
          return false;
        }
      }

      setShowMenu(false);
      onClose?.();
    };

    const [appendTarget, setAppendTarget] =
      useState<TippyProps["appendTo"]>(appendTo);

    useEffect(() => {
      setAppendTarget(appendTo ?? document.body);
    }, [appendTo]);

    return (
      <Tippy
        {...tippyProps}
        appendTo={appendTarget}
        interactive
        popperOptions={{
          modifiers: [
            { name: "arrow", enabled: false },
            {
              name: "preventOverflow",
              options: {
                altAxis: true,
                tether: false,
              },
            },
            {
              name: "sameWidth",
              enabled: sameWidth,
              fn: ({ state }) => {
                state.styles.popper.width = `${state.rects.reference.width}px`;
              },
              phase: "beforeWrite",
              requires: ["computeStyles"],
            },
          ],
        }}
        plugins={[
          {
            name: "hideOnEsc",
            defaultValue: true,
            fn({ hide }) {
              function onKeyDown(event: KeyboardEvent) {
                if (event.key === "Escape") {
                  event.stopPropagation();
                  hide();
                }
              }

              return {
                onShow() {
                  document?.addEventListener("keydown", onKeyDown);
                },
                onHide() {
                  document?.removeEventListener("keydown", onKeyDown);
                },
              };
            },
          },
        ]}
        visible={showMenu}
        placement={placement}
        onShow={() => setShowMenu(true)}
        onHide={handleClose}
        onClickOutside={handleClose}
        render={(props) =>
          showMenu && (
            <Menu
              innerRef={innerRef}
              {...menuProps}
              {...props}
              maxHeight={maxHeight}
              maxWidth={maxWidth}
            >
              {Children.map(children, (child) => {
                if (!isValidElement(child)) {
                  return null;
                }

                if (
                  child.props.tabIndex === undefined ||
                  child.props.tabIndex < 0
                ) {
                  return child;
                }

                return cloneElement(child as ReactElement, {
                  onClick:
                    typeof child.props.onClick === "function" &&
                    !child.props.disabled
                      ? (...args) => {
                          if (closeOnSelect) {
                            handleClose(undefined, ...args);
                          }

                          child.props.onClick(...args);
                        }
                      : undefined,
                });
              })}
            </Menu>
          )
        }
      >
        <OverflowMenuOpener
          {...props}
          width={openerWidth}
          onClick={
            !disabled
              ? (e) => {
                  e.stopPropagation();
                  setShowMenu((prevState) => !prevState);
                }
              : undefined
          }
        >
          {opener({
            isOpen: showMenu,
            open: () => setShowMenu(true),
            close: handleClose,
          })}
        </OverflowMenuOpener>
      </Tippy>
    );
  }
);

OverflowMenu.displayName = "OverflowMenu";

export default OverflowMenu;
