import React from "react";
import styled, { css } from "styled-components";
import { transparentize } from "polished";
import { CaretDown, LockSimple, IconProps } from "phosphor-react";

import { FieldProps, FieldContainerProps, FieldSize } from "./types";

export const FieldPlaceholder = styled.span<FieldProps>``;

export const Field = styled.div<FieldProps>`
  display: flex;
  align-items: center;
  gap: ${({ theme, size }) =>
    size === "large" ? theme.spacing(1.5) : theme.spacing(1)}px;
  padding: 0px
    ${({ theme, size }) =>
      size === "large" ? theme.spacing(2) : theme.spacing(1)}px;
  border: thin solid ${({ theme }) => theme.mosaiq.colors.grey80};
  border-radius: ${({ theme }) => theme.spacing(1)}px;
  background: ${({ theme }) => theme.mosaiq.colors.white};
  min-height: ${({ size, theme }) =>
    size === "small"
      ? theme.spacing(3)
      : size === "large"
        ? theme.spacing(6)
        : theme.spacing(4)}px;

  ${({ borderless }) =>
    borderless &&
    css`
      border: none !important;
      outline: none !important;
      padding-left: 0 !important;
      min-height: ${({ theme }) => theme.spacing(3)}px;
    `}

  ${({ readOnly }) =>
    readOnly &&
    css`
      cursor: default;
    `}

  ${({ autoSize, size, theme }) =>
    autoSize
      ? css`
          flex-wrap: wrap;
          padding: ${({ theme }) => theme.spacing(1)}px;
        `
      : css`
          flex-wrap: nowrap;
          max-height: ${size === "small"
            ? theme.spacing(3)
            : size === "large"
              ? theme.spacing(6)
              : theme.spacing(4)}px;
        `};

  ${({ size, theme }) =>
    size === "small"
      ? theme.mosaiq.typography.body4()
      : theme.mosaiq.typography.body2()}

  input, span {
    font-family: ${({ theme }) => theme.mosaiq.fonts.main};
    border: none;
    color: ${({ theme }) => theme.mosaiq.colors.title};
    background-color: inherit;
    outline: none;
    flex-grow: 1;
    padding: 0;

    ${({ readOnly }) =>
      readOnly &&
      css`
        cursor: default;
      `}

    ${({ size, theme }) =>
      size === "small"
        ? theme.mosaiq.typography.body4()
        : size === "large"
          ? theme.mosaiq.typography.body2()
          : theme.mosaiq.typography.body3()}

    &::placeholder, ${FieldPlaceholder} {
      color: ${({ theme }) => theme.mosaiq.colors.placeholder};
    }

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    input[type="number"] {
      -moz-appearance: textfield;
    }

    ${({ borderless, error }) =>
      borderless &&
      error &&
      css`
        color: ${({ theme }) => theme.mosaiq.colors.error};

        &::placeholder,
        ${FieldPlaceholder} {
          color: ${({ theme }) => theme.mosaiq.colors.error};
        }
      `}
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type="number"] {
    -moz-appearance: textfield;
  }

  input:disabled {
    color: ${({ theme }) => theme.mosaiq.colors.body};
  }

  ${({ theme, error, disabled, focus }) => {
    if (error) {
      return css`
        border: thin solid ${theme.mosaiq.colors.error};
      `;
    }

    if (disabled) {
      return css`
        background: ${theme.mosaiq.colors.grey};
        border: thin solid ${theme.mosaiq.colors.grey60};
      `;
    }

    if (focus) {
      return css`
        border: thin solid ${theme.mosaiq.colors.grey60};
        outline: 1px solid ${transparentize(0.5, theme.mosaiq.colors.action)};
      `;
    }

    return css`
      &:hover {
        border: thin solid ${theme.mosaiq.colors.passive};
      }
    `;
  }}
`;

export const FieldContainer = styled.div<FieldContainerProps>`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing(0.5)}px;

  ${({ fullWidth }) =>
    fullWidth &&
    css`
      flex: 1;
      width: 100%;
      input {
        width: 100%;
      }
    `}

  & > label {
    display: inline-flex;
  }
`;

interface DropdownArrowProps extends IconProps {
  open?: boolean;
  locked?: boolean;
  heading?: boolean;
  disabled?: boolean;
  size?: FieldSize;
}

export const DropdownArrow = styled(
  ({ locked, className, disabled, size }: DropdownArrowProps) =>
    disabled ? null : locked ? (
      <LockSimple
        weight="bold"
        width={size === "small" ? 14 : 16}
        className={className}
      />
    ) : (
      <CaretDown
        weight="bold"
        width={size === "small" ? 14 : 16}
        className={className}
      />
    )
)`
  ${({ theme }) => theme.mosaiq.typography.body2({ singleLine: true })};
  color: ${({ theme }) => theme.mosaiq.colors.passive};
  transition: transform 100ms ease;
  transform: ${({ open, locked, heading }) =>
    !locked && `rotate(${heading ? (open ? 0 : 90) : +!!open * 180}deg)`};
`;
