import styled, { css } from 'styled-components';

import { SkeletonProps } from './types';

export const SkeletonItem = styled.div<SkeletonProps>`
  ${({ theme, width }) =>
    !!width
      ? css`
          width: ${typeof width === 'string' ? width : `${theme.spacing(width)}px`};
        `
      : css`
          width: 100%;
        `}

  ${({ theme, height }) =>
    !!height
      ? css`
          height: ${typeof height === 'string' ? height : `${theme.spacing(height)}px`};
        `
      : css`
          height: 100%;
        `}

  background-color: ${({ theme }) => theme.colors.grey};
  border-radius: ${({ theme, borderRadius = 1 }) =>
    typeof borderRadius === 'string' ? borderRadius : `${theme.spacing(borderRadius)}px`};
  background-image: linear-gradient(
    to right,
    transparent,
    ${({ theme }) => theme.colors.white} 50%,
    transparent
  );
  background-size: 400% 100%;
  background-repeat: no-repeat;
  background-position: 0 0;
  animation-name: shimmer;
  animation-duration: 1.5s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;

  @keyframes shimmer {
    0% {
      background-position: 200% 0;
    }

    100% {
      background-position: -200% 0;
    }
  }
`;
