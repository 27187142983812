import {
  css,
  CSSObject,
  Interpolation,
  InterpolationFunction,
  ThemedStyledProps,
  DefaultTheme,
} from 'styled-components';

import breakpoints, { heights } from './breakpoints';
import { checkScreenSize, ScreenSize } from '../utils';

const screenSize = (size: ScreenSize) => {
  const { min, max } = checkScreenSize(size);

  const conditions: string[] = [];

  if (min) {
    conditions.push(`min-width: ${breakpoints[min] + 1}px`);
  }

  if (max) {
    conditions.push(`max-width: ${breakpoints[max]}px`);
  }

  return <P extends object>(
    first:
      | TemplateStringsArray
      | CSSObject
      | InterpolationFunction<ThemedStyledProps<P, DefaultTheme>>,
    ...interpolations: Array<Interpolation<ThemedStyledProps<P, DefaultTheme>>>
  ) => {
    return css`
      @media (${conditions.join(' and ')}) {
        ${css(first, ...interpolations)};
      }
    `;
  };
};

const screenHeight = (size: ScreenSize) => {
  const { min, max } = checkScreenSize(size);

  const conditions: string[] = [];

  if (min) {
    conditions.push(`min-height: ${heights[min] + 1}px`);
  }

  if (max) {
    conditions.push(`max-height: ${heights[max]}px`);
  }

  return <P extends object>(
    first:
      | TemplateStringsArray
      | CSSObject
      | InterpolationFunction<ThemedStyledProps<P, DefaultTheme>>,
    ...interpolations: Array<Interpolation<ThemedStyledProps<P, DefaultTheme>>>
  ) => {
    return css`
      @media (${conditions.join(' and ')}) {
        ${css(first, ...interpolations)};
      }
    `;
  };
};

const mediaQueries = {
  screenSize,
  screenHeight,
  extraSmallOnly: screenSize({ max: 'extraSmall' }),
  smallOnly: screenSize({ min: 'extraSmall', max: 'small' }),
  mediumOnly: screenSize({ min: 'small', max: 'medium' }),
  largeOnly: screenSize({ min: 'medium', max: 'large' }),
  extraLargeOnly: screenSize({ min: 'large', max: 'extraLarge' }),
  extraExtraLargeOnly: screenSize({ min: 'extraLarge' }),
} as const;

export default mediaQueries;
