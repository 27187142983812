import styled, { css, DefaultTheme } from 'styled-components';
import { Property } from 'csstype';

import { SpacerProps } from './types';

function castLength(
  theme: DefaultTheme,
  length?: number | string,
): Property.Width | Property.Height | undefined {
  return typeof length === 'number' ? `${theme.mosaiq.spacing(length)}px` : length;
}

export const SpacerElement = styled.div<SpacerProps>`
  ${({ theme, width, minWidth, maxWidth, height, minHeight, maxHeight, display, flex }) => css`
    display: ${display};
    flex: ${flex};
    width: ${castLength(theme, width)};
    min-width: ${castLength(theme, minWidth)};
    max-width: ${castLength(theme, maxWidth)};
    height: ${castLength(theme, height)};
    min-height: ${castLength(theme, minHeight)};
    max-height: ${castLength(theme, maxHeight)};
  `}
`;
