import styled from 'styled-components';
import { rgba } from 'polished';
// import { Typography } from '..';

export const ToastWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: ${({ theme }) => theme.spacing(1)}px ${({ theme }) => theme.spacing(1)}px
    ${({ theme }) => theme.spacing(1)}px ${({ theme }) => theme.spacing(1.5)}px;
  background-color: ${({ theme }) => rgba(theme.colors.black, 0.85)};
  border-radius: ${({ theme }) => theme.spacing(1)}px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.05), 0px 5px 10px rgba(0, 0, 0, 0.05),
    0px 2px 4px rgba(0, 0, 0, 0.05), 0px 10px 20px rgba(0, 0, 0, 0.05);

  max-width: 480px;
  min-height: 40px;

  > *:not(:last-child) {
    margin-right: ${({ theme }) => theme.spacing(3)}px;
  }
`;

export const ToastContentWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;

  > *:not(:last-child) {
    margin-right: ${({ theme }) => theme.spacing(1)}px;
  }

  ${({ theme }) => theme.mosaiq.mediaQueries.screenSize({ max: 'extraSmall' })`
    max-width: 100%;
  `}
`;

export const ToastContent = styled.div<{ $hasIcon: boolean }>`
  color: ${({ theme }) => theme.colors.white};
  width: max-content;
  min-width: 320px;
  padding-right: ${({ theme }) => theme.spacing(2)}px;
  margin-left: ${({ $hasIcon, theme }) => ($hasIcon ? theme.spacing(1) : 0)};

  ${({ theme }) => theme.mosaiq.typography.body3()};

  ${({ theme }) => theme.mosaiq.mediaQueries.screenSize({ max: 'extraSmall' })`
    min-width: unset;
  `}
`;

export const ToastCTAs = styled.div`
  display: flex;
  align-items: center;
  margin-left: auto;

  > *:not(:last-child) {
    margin-right: ${({ theme }) => theme.spacing(0.5)}px;
  }
`;
