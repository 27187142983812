import { FormEvent, MouseEvent, useEffect, useState } from "react";
import { darken } from "polished";
import { useTheme } from "styled-components";
import { ArrowRight, CheckCircle, WarningCircle, X } from "phosphor-react";

import config from "@/common/config";
import { analytics, logEvent, createEmail } from "@/common/firebaseApp";
import { useIsExtraSmallOnly } from "@/hooks/useMediaQueries";

import {
  WaitlistFormWrapper,
  WaitlistInput,
  WaitlistFormSubmit,
} from "./WaitlistForm.styles";

interface WaitlistFormProps {
  customInputClassName?: string;
}

const WaitlistForm = ({ customInputClassName }: WaitlistFormProps) => {
  const theme = useTheme();
  const isMobileView = useIsExtraSmallOnly();

  const [userEmail, setUserEmail] = useState("");
  const [userEmailValid, setUserEmailValid] = useState(false);
  const [userEmailError, setUserEmailError] = useState(false);
  const [userEmailSubmitSuccess, setUserEmailSubmitSuccess] = useState(false);

  const [loading, setLoading] = useState(false);

  const handleFormSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const formTarget = e.target as HTMLFormElement;
    const input = formTarget[0] as HTMLInputElement;
    const email = input?.value;
    const valid = config.regex.email.test(email);

    if (!valid) {
      if (window.location.hostname !== "localhost") {
        logEvent(analytics, "submit_email_invalid_email", {
          isMobile: isMobileView,
        });
      }

      setUserEmailValid(false);
      return;
    }

    if (window.location.hostname !== "localhost") {
      logEvent(analytics, "submit_email_init", { isMobile: isMobileView });
    }

    if (email) {
      try {
        setLoading(true);
        await createEmail({ email: input.value });
        setUserEmailError(false);
        setUserEmail("");
        setUserEmailSubmitSuccess(true);
        setLoading(false);

        if (window.location.hostname !== "localhost") {
          logEvent(analytics, "submit_email_success", {
            isMobile: isMobileView,
          });
        }
      } catch (e) {
        setLoading(false);
        setUserEmailError(true);

        if (window.location.hostname !== "localhost") {
          logEvent(analytics, "submit_email_fail", { isMobile: isMobileView });
        }
      }
    }
  };

  useEffect(() => {
    const valid = config.regex.email.test(userEmail);
    setUserEmailValid(valid);

    if (valid) setUserEmailError(false);
  }, [userEmail]);

  return (
    <form onSubmit={handleFormSubmit}>
      <WaitlistFormWrapper direction="column" gap={2} justify={"center"}>
        <WaitlistInput
          inputProps={{
            placeholder: userEmailSubmitSuccess
              ? "You're all set!"
              : "Enter email to get early access",
            type: "email",
            name: "email",
            value: userEmail,
            onChange: (e) => setUserEmail(e.target.value),
            onFocus: () => {
              if (window.location.hostname !== "localhost") {
                logEvent(analytics, "input_focused", {
                  isMobile: isMobileView,
                });
              }
            },
            onBlur: () => {
              if (!!userEmail) {
                if (window.location.hostname !== "localhost") {
                  logEvent(analytics, "input_user_has_typed", {
                    isMobile: isMobileView,
                  });
                }
              } else {
                if (window.location.hostname !== "localhost") {
                  logEvent(analytics, "input_user_left_without_typing", {
                    isMobile: isMobileView,
                  });
                }
              }
            },
          }}
          error={userEmailError}
          disabled={userEmailSubmitSuccess}
          className={customInputClassName}
        />
        <WaitlistFormSubmit
          variant="primary"
          size="large"
          style={{
            backgroundColor: userEmailSubmitSuccess
              ? darken(0.2, theme.mosaiq.colors.emerald)
              : userEmailError
                ? theme.mosaiq.colors.error
                : userEmailValid
                  ? theme.mosaiq.colors.emerald
                  : theme.mosaiq.colors.emerald,
          }}
          onClick={(e) => {
            e.stopPropagation();
          }}
          // @ts-ignore
          type="submit"
        >
          {loading ? (
            <div className="loader"></div>
          ) : userEmailSubmitSuccess ? (
            <CheckCircle
              weight="bold"
              size={40}
              color={theme.mosaiq.colors.white}
            />
          ) : userEmailError ? (
            <WarningCircle weight="bold" />
          ) : (
            <ArrowRight
              weight="bold"
              color={theme.mosaiq.colors.white}
              size={40}
            />
          )}
        </WaitlistFormSubmit>
      </WaitlistFormWrapper>
    </form>
  );
};

export default WaitlistForm;
