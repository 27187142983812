import React, { PropsWithChildren } from 'react';

import { mosaiqStyled } from '../../lib';
import { SpacerElement } from './Spacer.styles';
import { SpacerProps } from './types';

const Spacer = mosaiqStyled<PropsWithChildren<SpacerProps>, HTMLDivElement>(
  ({ children, ...props }) => {
    void children;
    return <SpacerElement {...props} />;
  },
);

export default Spacer;
