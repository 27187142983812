import { createGlobalStyle } from "styled-components";

import AeonikBoldItalicWoff2 from "@/assets/fonts/aeonik/Aeonik-BoldItalic.woff2";
import AeonikBoldItalicWoff from "@/assets/fonts/aeonik/Aeonik-BoldItalic.woff";
import AeonikBoldWoff2 from "@/assets/fonts/aeonik/Aeonik-Bold.woff2";
import AeonikBoldWoff from "@/assets/fonts/aeonik/Aeonik-Bold.woff";
import AeonikRegularItalicWoff2 from "@/assets/fonts/aeonik/Aeonik-RegularItalic.woff2";
import AeonikRegularItalicWoff from "@/assets/fonts/aeonik/Aeonik-RegularItalic.woff";
import AeonikLightItalicWoff2 from "@/assets/fonts/aeonik/Aeonik-LightItalic.woff2";
import AeonikLightItalicWoff from "@/assets/fonts/aeonik/Aeonik-LightItalic.woff";
import AeonikLightWoff2 from "@/assets/fonts/aeonik/Aeonik-Light.woff2";
import AeonikLightWoff from "@/assets/fonts/aeonik/Aeonik-Light.woff";
import AeonikRegularWoff2 from "@/assets/fonts/aeonik/Aeonik-Regular.woff2";
import AeonikRegularWoff from "@/assets/fonts/aeonik/Aeonik-Regular.woff";

export const GlobalStyle = createGlobalStyle`
  @font-face {
    font-family: 'Aeonik';
    src: url(${AeonikBoldItalicWoff2}) format('woff2'),
      url(${AeonikBoldItalicWoff}) format('woff');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
  }

  @font-face {
    font-family: 'Aeonik';
    src: url(${AeonikBoldWoff2}) format('woff2'),
      url(${AeonikBoldWoff}) format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'Aeonik';
    src: url(${AeonikRegularItalicWoff2}) format('woff2'),
      url(${AeonikRegularItalicWoff}) format('woff');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
  }

  @font-face {
    font-family: 'Aeonik';
    src: url(${AeonikLightItalicWoff2}) format('woff2'),
      url(${AeonikLightItalicWoff}) format('woff');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
  }

  @font-face {
    font-family: 'Aeonik';
    src: url(${AeonikLightWoff2}) format('woff2'),
      url(${AeonikLightWoff}) format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'Aeonik';
    src: url(${AeonikRegularWoff2}) format('woff2'),
      url(${AeonikRegularWoff}) format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'Aeonik';
    src: url(${AeonikBoldWoff2}) format('woff2'),
      url(${AeonikBoldWoff}) format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
  }

  *,
  &:after,
  &:before {
    box-sizing: border-box;
  }

  html,
  body,
  div,
  span,
  applet,
  object,
  iframe,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  blockquote,
  pre,
  a,
  abbr,
  acronym,
  address,
  big,
  cite,
  code,
  del,
  dfn,
  em,
  img,
  ins,
  kbd,
  q,
  s,
  samp,
  small,
  strike,
  strong,
  sub,
  sup,
  tt,
  var,
  b,
  u,
  i,
  center,
  dl,
  dt,
  dd,
  ol,
  ul,
  li,
  fieldset,
  form,
  label,
  legend,
  table,
  caption,
  tbody,
  tfoot,
  thead,
  tr,
  th,
  td,
  article,
  aside,
  canvas,
  details,
  embed,
  figure,
  figcaption,
  footer,
  header,
  hgroup,
  menu,
  nav,
  output,
  ruby,
  section,
  summary,
  time,
  mark,
  audio,
  video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
  }

  html,
  body {
    width: 100%;
    font-family: ${({ theme }): string => theme.mosaiq.fonts.main};
    overscroll-behavior: none;
  }

  /* HTML5 display-role reset for older browsers */
  article,
  aside,
  details,
  figcaption,
  figure,
  footer,
  header,
  hgroup,
  menu,
  nav,
  section {
    display: block;
  }

  body {
    line-height: 1;
  }

  ol,
  ul {
    list-style: none;
  }

  blockquote,
  q {
    quotes: none;
  }

  blockquote:before,
  blockquote:after,
  q:before,
  q:after {
    content: '';
    content: none;
  }

  table {
    border-collapse: collapse;
    border-spacing: 0;
  }

  a {
    text-decoration: none;
  }

  strong {
    font-weight: bold;
  }

  em {
    font-style: italic;
  }

  body,
  p,
  a {
    ${({ theme }) => theme.mosaiq.typography.body1()}; 
    color: ${({ theme }): string => theme.mosaiq.colors.title};
  }

  h1 {
    ${({ theme }) => theme.mosaiq.typography.title1()};
  }

  h2 {
    ${({ theme }) => theme.mosaiq.typography.title2()};
  }

  h3 {
    ${({ theme }) => theme.mosaiq.typography.title3()};
  }

  h4 {
    ${({ theme }) => theme.mosaiq.typography.title4()};
  }

  h5 {
    ${({ theme }) => theme.mosaiq.typography.title5()};
  }

  h6 {
    ${({ theme }) => theme.mosaiq.typography.title6()};
  }
`;
