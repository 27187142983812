import styled from "styled-components";
import { Box, Typography } from "@/components/ui";

export const HomeStoriesAvatar = styled(Box)`
  border-radius: 50%;
  border-width: 8px;
  overflow: hidden;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  ${({ theme }) => theme.mosaiq.mediaQueries.screenSize({
    max: "extraSmall",
  })`
    border-width: 0px;
  `}
`;

export const HomeStoriesGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 8px;
`;
