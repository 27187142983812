/**
 * These are max-width breakpoints and divide the screen width spectrum into 6 types
 */
export default {
  extraSmall: 576,
  small: 1024,
  medium: 1280,
  large: 1440,
  extraLarge: 1920,
} as const;

export const heights = {
  extraSmall: 667,
  small: 740,
  medium: 844,
  large: 1024,
  extraLarge: 1180,
} as const;
