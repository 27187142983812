import styled from "styled-components";
import HomeHero from "@/assets/backgrounds/home_hero_02.png";
import HomeHeroMobile from "@/assets/backgrounds/home_hero_03.png";
import { Box } from "@/components/ui";

export const HomeWrapper = styled.div`
  // background: ${({ theme }) => theme.mosaiq.colors.black};
  // background: ${({ theme }) =>
    theme.mosaiq.colors.black} center top / contain
  //   no-repeat
  //   url(https://images.unsplash.com/photo-1419242902214-272b3f66ee7a?q=80&w=3026&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D);
  // background-color: #000;

  background: #000 url(${HomeHero});
  background-repeat: no-repeat;
  background-position: 0 -200px;
  background-size: contain;

  width: 100%;
  overflow: hidden;

  ${({ theme }) => theme.mosaiq.mediaQueries.screenSize({
    max: "extraSmall",
  })`
    background-position: -4px -150px;
    background-size: auto;
  `}
`;

export const FullImageBox = styled(Box)`
  overflow: hidden;

  img {
    width: 100%;
    height: 100%;
  }
`;
