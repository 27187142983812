import React from 'react';

import { mosaiqStyled } from '../../lib';

import { SkeletonProps } from './types';

import { SkeletonItem } from './Skeleton.styles';

const Skeleton = mosaiqStyled<SkeletonProps>((props) => <SkeletonItem {...props} />);

Skeleton.displayName = 'Skeleton';

export default Skeleton;
