import styled, { css } from 'styled-components';
import { rgba } from 'polished';
import { Property } from 'csstype';

import { TooltipSize, TooltipVariant } from './types';

export const TooltipOpener = styled.div`
  display: inline-flex;
  max-width: max-content;
`;

export const TooltipContainer = styled.div<{
  variant: TooltipVariant;
  size: TooltipSize;
  maxWidth?: number | Property.MaxWidth;
}>`
  display: inline-flex;
  max-width: ${({ theme, maxWidth }) =>
    maxWidth && typeof maxWidth !== 'number' ? maxWidth : `${maxWidth || theme.spacing(30)}px`};

  ${({ theme, size }) => {
    if (size === 'regular') {
      return css`
        padding: ${`${theme.spacing(1)}px ${theme.spacing(1.5)}px`};
        ${theme.mosaiq.mixins.squircle({ borderRadius: theme.spacing(1) })};
      `;
    }

    // small or other
    return css`
      padding: ${`${theme.spacing(0.5)}px ${theme.spacing(1)}px`};
      ${theme.mosaiq.mixins.squircle({ borderRadius: theme.spacing(0.75) })};
      ${({ theme }) => theme.mosaiq.typography.body3({ singleLine: true })}
    `;
  }}

  ${({ theme, variant }) => {
    if (variant === 'light') {
      return css`
        background-color: ${rgba(theme.mosaiq.colors.white, 0.96)};
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.05), 0px 5px 10px rgba(0, 0, 0, 0.05),
          0px 2px 4px rgba(0, 0, 0, 0.05), 0px 10px 20px rgba(0, 0, 0, 0.05);
      `;
    }

    // dark
    return css`
      background-color: ${rgba(theme.mosaiq.colors.black, 0.9)};
      box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.05), 0px 5px 10px rgba(0, 0, 0, 0.05),
        0px 2px 4px rgba(0, 0, 0, 0.05), 0px 10px 20px rgba(0, 0, 0, 0.05);
      color: ${theme.mosaiq.colors.white};
    `;
  }}
`;
