import styled from "styled-components";
import { Flex } from "@/components/ui";

import config from "@/common/config";

export const FooterContainer = styled.div`
  width: 100%;
  position: relative;
  background: ${({ theme }) => theme.mosaiq.colors.grey40};
`;

export const FooterWrapper = styled(Flex)`
  margin: 0 auto;
  width: 100%;
  max-width: ${config.appConfig.appWrapperWidth}px;
  text-align: center;
  position: relative;
  z-index: 1;
`;
