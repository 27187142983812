import React, { useMemo } from 'react';

import { mosaiqStyled } from '../../lib';

import { TypographyProps } from './types';

import { TypographyWrapper } from './Typography.styles';

const Typography = mosaiqStyled<TypographyProps, HTMLDivElement>(
  ({ children, element = 'div', link, variant = 'body1', innerRef, ...props }) => {
    const normalizedElement = useMemo(() => {
      switch (variant) {
        case 'title1':
          return 'h1';

        case 'title2':
          return 'h2';

        case 'title3':
          return 'h3';

        case 'title4':
          return 'h4';

        case 'title5':
          return 'h5';

        case 'title6':
          return 'h6';

        default:
          return element;
      }
    }, [element]);

    return (
      <TypographyWrapper
        as={normalizedElement}
        ref={innerRef}
        link={link || normalizedElement === 'a'}
        variant={variant}
        {...props}
      >
        {children}
      </TypographyWrapper>
    );
  },
);

Typography.displayName = 'Typography';

export default Typography;
