const brandColors = {
  apricotPeach: "#FDD7CB",
  blackWhite: "#FFFEF8",
  coral: "#F87853",
  emerald: "#48C17A",
  emeraldWhite: "#E7F8EE",
  iceCold: "#AAF0E8",
  lavender: "#8E76DC",
  lavenderLight: "#EFEDFB",
  lavenderWhite: "#F4F1FF",
  maverick: "#E6D4DF",
  melrose: "#CABAFD",
  mineShaft: "#292929",
  moonRaker: "#ECEEFB",
  mould: "#C8F0D9",
  navajoWhite: "#FFE0AB",
  persianBlue: "#0057FF",
  plum: "#81265D",
  portage: "#CEDFFF",
  sandyBrown: "#FBB254",
  sandyHeath: "#FFF9EE",
  softPeach: "#FEEDE9",
  softPlum: "#FAF6F9",
  spray: "#1CFFE4",
  whiteIce: "#E8FFFC",
  pink: "#ff279d",
} as const;

const defaultColors = {
  black: "#000000",
  white: "#FFFFFF",
  title: "#292929",
  body: "#595B60",
  passive: "#8D9097",
  placeholder: "#B9BBC1",
  error: "#F87853",
  warning: "#FBB254",
  success: "#6FCF97",
  action: "#0057FF",
  grey: "#F4F5F7",
  grey20: "#E7EAF0",
  grey40: "#E3E5E9",
  grey60: "#D3D6DF",
  grey80: "#B9BBC1",
} as const;

export default {
  ...defaultColors,
  ...brandColors,
};
