import React, { useEffect, useRef, Children, cloneElement, MutableRefObject } from 'react';
import { CaretRight } from 'phosphor-react';

import { mosaiqStyled } from '../../lib';

import { ListItem } from '../ListItem';
import { OverflowMenu } from '../OverflowMenu';

import { MenuItemProps } from './types';

import { MenuItemContainer, CascadeItemContainer } from './MenuItem.styles';

const MenuItem = mosaiqStyled<MenuItemProps>(
  ({
    autoFocus,
    tabIndex = -1,
    disabled = false,
    avatar,
    active,
    primaryText,
    primaryTextProps,
    secondaryText,
    secondaryTextProps,
    primaryAction,
    secondaryAction,
    size,
    onClick,
    cascade,
    selected,
    ...props
  }) => {
    const menuItemRef = useRef<HTMLDivElement>(null);
    const timeoutRef = useRef<NodeJS.Timeout>();
    const closeRef = useRef(null) as MutableRefObject<(() => void) | null>;

    useEffect(() => {
      if (autoFocus) {
        menuItemRef.current?.focus?.({
          preventScroll: true,
        });
      }
    }, [autoFocus]);

    return (
      <MenuItemContainer
        {...props}
        selected={selected}
        onClick={
          !!onClick
            ? (e) => {
                e.stopPropagation();
                onClick(e);
              }
            : undefined
        }
        disabled={disabled}
        tabIndex={tabIndex}
        onMouseEnter={() => {
          if (tabIndex >= 0) {
            menuItemRef.current?.focus?.({
              preventScroll: true,
            });
          }
        }}
        ref={menuItemRef}
      >
        {!!cascade && cascade?.length > 0 ? (
          <CascadeItemContainer>
            <OverflowMenu
              appendTo="parent"
              placement="right-start"
              tippyProps={{
                offset: [-8, -20],
              }}
              opener={({ isOpen, open, close }) => {
                closeRef.current = close;

                return (
                  <ListItem
                    data-testid={'menuItemValue'}
                    disabled={disabled}
                    avatar={avatar}
                    primaryText={primaryText}
                    primaryTextProps={primaryTextProps}
                    secondaryText={secondaryText}
                    secondaryTextProps={secondaryTextProps}
                    primaryAction={<CaretRight />}
                    secondaryAction={secondaryAction}
                    size={size}
                    active={active}
                    onMouseEnter={() => {
                      clearTimeout(timeoutRef.current);
                      !isOpen && open();
                    }}
                    onMouseLeave={() => {
                      timeoutRef.current = setTimeout(() => {
                        close();
                      }, 100);
                    }}
                    onClick={
                      !!onClick
                        ? (e: React.MouseEvent) => {
                            e.stopPropagation();
                            onClick(e);
                          }
                        : undefined
                    }
                  />
                );
              }}
            >
              <div
                onMouseLeave={() => {
                  closeRef.current?.();
                }}
                onMouseEnter={() => {
                  clearTimeout(timeoutRef.current);
                }}
              >
                {Children.map(cascade, (child) => {
                  return cloneElement(child, {
                    onMouseEnter: () => {
                      clearTimeout(timeoutRef.current);
                    },
                  });
                })}
              </div>
            </OverflowMenu>
          </CascadeItemContainer>
        ) : (
          <ListItem
            data-testid={'menuItemValue'}
            disabled={disabled}
            avatar={avatar}
            primaryText={primaryText}
            primaryTextProps={primaryTextProps}
            secondaryText={secondaryText}
            secondaryTextProps={secondaryTextProps}
            primaryAction={primaryAction}
            secondaryAction={secondaryAction}
            size={size}
            active={active}
            selected={selected}
          />
        )}
      </MenuItemContainer>
    );
  },
);

export default MenuItem;
