import { useEffect, useState } from "react";
import { Button } from "../../ui";

import { useIsExtraSmallOnly } from "@/hooks/useMediaQueries";
import {
  NavigationContainer,
  NavigationWrapper,
  AppTypographyLogo,
} from "./Navigations.styles";

const Navigation = () => {
  const isMobileView = useIsExtraSmallOnly();

  const [headerActive, setHeaderActive] = useState(false);

  const onJoinWaitlist = () => {
    const footerInput = document.querySelector(
      ".footerInput input"
    ) as HTMLInputElement;

    if (footerInput) {
      footerInput.focus();
    }
  };

  useEffect(() => {
    const onScroll = () => {
      if (window.scrollY > window.innerHeight) setHeaderActive(true);
      else setHeaderActive(false);
    };

    window.addEventListener("scroll", onScroll);

    return () => {
      window.removeEventListener("scroll", onScroll);
    };
  }, []);

  return (
    <NavigationContainer active={headerActive}>
      <NavigationWrapper
        justify={"space-between"}
        padding={isMobileView ? [0, 2] : 0}
      >
        <AppTypographyLogo
          variant={isMobileView ? "title4" : "title3"}
          color={headerActive ? "black" : "white"}
          fontWeight={500}
        >
          EternalTrust.co
        </AppTypographyLogo>

        <Button
          variant="outline"
          size={isMobileView ? "regular" : "large"}
          onClick={onJoinWaitlist}
        >
          Get Early Access
        </Button>
      </NavigationWrapper>
    </NavigationContainer>
  );
};

export default Navigation;
