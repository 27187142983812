import React, { PropsWithChildren, useState } from 'react';
import { CaretDown } from 'phosphor-react';

import { mosaiqStyled } from '../../lib';
import { ExpanderProps } from './types';
import { ExpanderContent, ExpanderItem, ExpanderSummary, ExpanderLabel } from './Expander.styles';

const Expander = mosaiqStyled<PropsWithChildren<ExpanderProps>>(
  ({ label = 'Show', open = false, children, ...props }) => {
    const [isOpen, setOpen] = useState<boolean>(open);

    return (
      <ExpanderItem open={isOpen} {...props}>
        <ExpanderContent>{children}</ExpanderContent>
        <ExpanderSummary onClick={() => setOpen((o) => !o)} role="button">
          <ExpanderLabel>{label}</ExpanderLabel>
          <CaretDown />
        </ExpanderSummary>
      </ExpanderItem>
    );
  },
);

export default Expander;
