// @ts-nocheck
import styled, { css } from "styled-components";

import { selfOrFunction } from "../../utils";
import { BoxProps } from "./types";
import { SpacerElement } from "../Spacer/Spacer.styles";

export const BoxElement = styled(SpacerElement)<BoxProps>`
  ${({ theme, position, borderRadius }) => css`
    position: ${position};
    border-radius: ${borderRadius && theme.mosaiq.spacing(borderRadius) + "px"};
  `}

  background-color: ${(props) => selfOrFunction(props, props.backgroundColor)};

  ${(props) =>
    props.border &&
    css`
      border: thin solid
        ${props.borderColor
          ? selfOrFunction(props, props.borderColor)
          : props.theme.mosaiq.colors.grey60};
    `}
`;
