import styled, { css } from 'styled-components';

export const TextareaContainer = styled.div<{
  resize: boolean;
  disabled: boolean;
  error: boolean;
  borderless: boolean;
  width?: number | string;
}>`
  display: flex;
  flex-direction: column;

  ${({ width, theme }) =>
    !!width &&
    css`
      width: ${typeof width === 'number' ? `${theme.spacing(width)}px` : width};
    `}

  textarea {
    width: 100%;
    font-family: ${({ theme }) => theme.mosaiq.fonts.main};
    border: thin solid ${({ theme }) => theme.mosaiq.colors.grey60};
    border-radius: ${({ theme }) => theme.spacing(1)}px;
    padding: ${({ theme }) => theme.spacing(1)}px;
    margin: 0;
    outline: none;

    ${({ theme }) => theme.mosaiq.typography.body3()}

    ${({ resize }) =>
      !resize &&
      css`
        resize: none;
      `}

    

    &::placeholder {
      color: ${({ theme }) => theme.mosaiq.colors.placeholder};
    }

    ${({ theme, error, disabled, borderless }) => {
      if (borderless) {
        return css`
          border: none;
          padding-left: 0;

          ${error &&
          css`
            color: ${({ theme }) => theme.mosaiq.colors.error};

            &::placeholder {
              color: ${({ theme }) => theme.mosaiq.colors.error};
            }
          `}
        `;
      }

      if (error) {
        return css`
          border: thin solid ${theme.mosaiq.colors.error};
        `;
      }

      if (disabled) {
        return css`
          background: ${theme.mosaiq.colors.grey};
          border: thin solid ${theme.mosaiq.colors.grey60};
        `;
      }

      return css`
        &:hover {
          border: thin solid ${theme.mosaiq.colors.placeholder};
        }

        &:focus {
          border: thin solid ${theme.mosaiq.colors.grey60};
          box-shadow: 0px 0px 0px 1px rgba(0, 87, 255, 0.5);
        }
      `;
    }}
  }
`;
