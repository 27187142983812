import styled from "styled-components";
import { Box, Flex, Typography } from "@/components/ui";

import config from "@/common/config";
import { rgba } from "polished";

export const HeaderContainer = styled.div`
  width: 100%;
  height: ${window.innerHeight}px;
  padding-top: ${config.appConfig.appNavigationHeight}px;
  position: relative;
  overflow: hidden;

  ${({ theme }) => theme.mosaiq.mediaQueries.screenSize({
    max: "extraSmall",
  })`
    padding: 0 24px;
  `}
`;

export const HeaderWrapper = styled(Flex).attrs({
  justify: "center",
})`
  margin: 0 auto;
  width: 100%;
  height: 100%;
  max-width: ${config.appConfig.appWrapperWidth}px;

  position: relative;
  z-index: 1;
`;

export const HeaderHeroText = styled(Flex).attrs({
  padding: [6, 0],
})`
  position: relative;
`;

export const HeaderTitle = styled(Typography)`
  font-size: 84px;
  letter-spacing: -2px;
  line-height: 80px;

  ${({ theme }) => theme.mosaiq.mediaQueries.screenSize({
    max: "extraSmall",
  })`
    font-size: 46px;
    line-height: 48px;
    letter-spacing: -1px;
  `}
`;

export const HeaderHeroIconBox = styled(Box)`
  text-align: center;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);

  img {
    object-fit: contain;
    text-align: center;
    width: 100%;
    height: 100%;
  }
`;
