// @ts-nocheck
import styled, { css } from "styled-components";

import { buttonBaseStyles, buttonColor, buttonBorder } from "../Button";

import { IconButtonProps } from "./types";

export const IconButtonContainer = styled.button<IconButtonProps>`
  ${buttonBaseStyles}
  justify-content: center;

  ${buttonColor}

  ${buttonBorder}

  ${({ size = "regular", theme }) => {
    switch (size) {
      case "large":
        return css`
          min-width: ${theme.mosaiq.spacing(5)}px;
          min-height: ${theme.mosaiq.spacing(5)}px;
          max-width: ${theme.mosaiq.spacing(5)}px;
          max-height: ${theme.mosaiq.spacing(5)}px;
        `;

      case "small":
        return css`
          min-width: ${theme.mosaiq.spacing(3)}px;
          min-height: ${theme.mosaiq.spacing(3)}px;
          max-width: ${theme.mosaiq.spacing(3)}px;
          max-height: ${theme.mosaiq.spacing(3)}px;
        `;

      default:
        return css`
          min-width: ${theme.mosaiq.spacing(4)}px;
          min-height: ${theme.mosaiq.spacing(4)}px;
          max-width: ${theme.mosaiq.spacing(4)}px;
          max-height: ${theme.mosaiq.spacing(4)}px;
        `;
    }
  }}

  svg,
  img {
    min-width: ${({ theme }) => theme.mosaiq.spacing(2)}px;
    min-height: ${({ theme }) => theme.mosaiq.spacing(2)}px;
    max-width: ${({ theme }) => theme.mosaiq.spacing(2)}px;
    max-height: ${({ theme }) => theme.mosaiq.spacing(2)}px;
  }
`;
