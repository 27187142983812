import { createRouter } from "@tanstack/react-router";
import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App.tsx";
import { routeTree } from "./routeTree.gen.ts";
import "./styles/tailwind.css";
import { MosaiqThemeProvider, GlobalStyle } from "./components/ui/index.ts";

const router = createRouter({ routeTree });

declare module "@tanstack/react-router" {
  interface Register {
    // This infers the type of our router and registers it across your entire project
    router: typeof router;
  }
}

const rootElement = document.querySelector("#root") as Element;
if (!rootElement.innerHTML) {
  const root = ReactDOM.createRoot(rootElement);
  root.render(
    <React.StrictMode>
      <MosaiqThemeProvider>
        <GlobalStyle />
        <App router={router} />
      </MosaiqThemeProvider>
    </React.StrictMode>
  );
}
