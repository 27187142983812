import styled, { css } from 'styled-components';
import { Property } from 'csstype';

import { ListItemSize } from './types';

export const ListItemAvatar = styled.div<{ align: Property.AlignSelf }>`
  align-self: ${({ align }) => align};
  display: flex;
`;

export const ListItemContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing(0.5)}px;
  min-width: 0;
  overflow: hidden;
`;

export const ListItemActions = styled.div`
  display: flex;
  align-items: center;
  margin-left: auto;
  padding-left: ${({ theme }) => theme.spacing(1)}px;

  & > * {
    margin-left: ${({ theme }) => theme.spacing(1)}px;
  }
`;

export const ListItemContainer = styled.div<{
  hasAvatar?: boolean;
  size: ListItemSize;
  active?: boolean;
  selected?: boolean;
  disabled?: boolean;
  inline?: boolean;
  actionsOnlyVisibleOnHover?: boolean;
  singleLine?: boolean;
}>`
  max-width: 100%;

  ${({ inline }) =>
    inline
      ? css`
          display: inline-flex;
          width: max-content;
        `
      : css`
          display: flex;
          width: 100%;
        `}

  align-items: center;

  ${({ theme, size, singleLine }) => {
    switch (size) {
      case 'small':
        return css`
          padding: ${theme.spacing(singleLine ? 1 : 1.5)}px;
          border-radius: ${theme.spacing(0.75)}px;
        `;

      case 'large':
        return css`
          padding: ${theme.spacing(1.5)}px;
          border-radius: ${theme.spacing(1.5)}px;
        `;

      default:
        return css`
          padding: ${theme.spacing(singleLine ? 1 : 1.5)}px;
          border-radius: ${theme.spacing(1)}px;
        `;
    }
  }}

  ${({ active, selected }) =>
    selected
      ? css`
          background-color: ${({ theme }) => theme.mosaiq.colors.grey40};
        `
      : active &&
        css`
          background-color: ${({ theme }) => theme.mosaiq.colors.grey};
        `}

  ${({ disabled }) =>
    disabled &&
    css`
      pointer-events: none;
      opacity: 0.5;
    `}

  ${({ theme, hasAvatar, size }) =>
    hasAvatar &&
    css`
      ${ListItemContent} {
        margin-left: ${theme.spacing(size === 'small' ? 1 : 1.5)}px;
      }
    `}

    ${({ actionsOnlyVisibleOnHover }) =>
    actionsOnlyVisibleOnHover &&
    css`
      ${ListItemActions} {
        opacity: 0;
        visibility: hidden;
        padding: 0;
        margin: 0;
        width: 0;

        & > * {
          margin: 0;
        }
      }
    `}

  &:hover {
    background-color: ${({ theme, selected }) =>
      selected ? theme.mosaiq.colors.grey40 : theme.mosaiq.colors.grey};

    ${({ actionsOnlyVisibleOnHover }) =>
      actionsOnlyVisibleOnHover &&
      css`
        ${ListItemActions} {
          opacity: 1;
          visibility: visible;
          margin-left: auto;
          padding-left: ${({ theme }) => theme.spacing(1)}px;
          width: initial;

          & > * {
            margin-left: ${({ theme }) => theme.spacing(1)}px;
          }
        }
      `}
  }

  ${({ onClick }) =>
    typeof onClick === 'function' &&
    css`
      cursor: pointer;

      &:active {
        background-color: ${({ theme }) => theme.mosaiq.colors.grey40};
      }
    `}
`;
