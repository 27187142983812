import React from 'react';

import { Typography } from '../Typography';
import { mosaiqStyled } from '../../lib';

import { PillWrapper, PillRemoveIcon } from './Pill.styles';
import { PillProps } from './types';
import { Tooltip } from '../Tooltip';

const Pill = mosaiqStyled<PillProps>(
  ({
    avatar,
    children,
    onRemove,
    activeRemove,
    wrap,
    color = 'body',
    background = 'grey',
    title,
    ...props
  }) => {
    const child = (
      <>
        {avatar}
        <Typography
          fontWeight={500}
          paddingLeft={!!avatar ? 0.5 : 0}
          variant="body3"
          ellipsis={wrap ? undefined : { lines: 1 }}
        >
          {children}
        </Typography>
        {!!onRemove && (
          <PillRemoveIcon
            onClick={(e) => {
              e.stopPropagation();
              onRemove(e);
            }}
          />
        )}
      </>
    );

    return (
      <PillWrapper
        {...props}
        wrap={wrap}
        hasAvatar={!!avatar}
        activeRemove={activeRemove}
        hasRemove={!!onRemove}
        color={color}
        background={background}
        as={!wrap && title ? Tooltip : undefined}
        opener={() => child}
        size="small"
        delay={[500, 0]}
      >
        {!wrap && title ? <Typography variant="body3">{title}</Typography> : child}
      </PillWrapper>
    );
  },
);

Pill.displayName = 'Pill';

export default Pill;
