import styled, { css } from 'styled-components';

import { ListItem } from '../ListItem';

export const MenuItemContainer = styled.div<{
  disabled: boolean;
  selected?: boolean;
}>`
  & svg {
    color: ${({ theme }) => theme.mosaiq.colors.passive};
  }

  ${({ disabled, selected, theme }) =>
    disabled
      ? css`
          pointer-events: none;
        `
      : css`
          cursor: pointer;

          &:focus {
            outline: none;

            ${ListItem} {
              background-color: ${selected ? theme.mosaiq.colors.grey40 : theme.mosaiq.colors.grey};
            }
          }
        `}
`;

export const CascadeItemContainer = styled.div`
  > div {
    width: 100%;
  }
`;
