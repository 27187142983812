import styled from 'styled-components';

export const AccordionContainer = styled.div``;

export const AccordionItemContainer = styled.details``;

export const AccordionItemSummary = styled.summary`
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  list-style: none;

  &::-webkit-details-marker {
    display: none;
  }
`;

export const AccordionItemContent = styled.div<{ open: boolean }>`
  display: grid;
  grid-template-rows: ${({ open }) => (open ? '1fr' : '0fr')};
  transition: grid-template-rows 200ms ease-out;
  > div {
    overflow: hidden;
  }
`;
