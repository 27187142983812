import React, { useMemo, useState, SetStateAction, useEffect } from 'react';
import Tippy from '@tippyjs/react/headless';
import { followCursor as followCursorPlugin } from 'tippy.js';

import { mosaiqStyled } from '../../lib';

import { TooltipProps } from './types';

import { TooltipContainer, TooltipOpener } from './Tooltip.styles';

const Tooltip = mosaiqStyled<TooltipProps, HTMLDivElement>(
  ({
    className,
    children,
    innerRef,
    disabled,
    variant = 'dark',
    opener,
    trigger = 'hover',
    placement = 'bottom',
    followCursor = false,
    size = 'regular',
    maxWidth,
    delay,
    disableTouch = true,
    ...props
  }) => {
    const [showTooltip, setShowTooltip] = useState<boolean>(false);

    const [isIOS, setIsIOS] = useState<boolean>(false);

    const triggerProps = useMemo(() => {
      const updateShowTooltip = (value: boolean | SetStateAction<boolean>) => {
        if (disabled || isIOS) {
          return;
        }

        setShowTooltip(value);
      };

      if (trigger === 'hover' && !isIOS) {
        return {
          onMouseEnter: () => {
            updateShowTooltip(true);
          },
          onMouseLeave: () => {
            updateShowTooltip(false);
          },
        };
      }

      if (trigger === 'click') {
        return {
          onClick: () => {
            updateShowTooltip((prevState) => !prevState);
          },
        };
      }

      return {};
    }, [trigger, disabled]);

    const [appendTarget, setAppendTarget] = useState<HTMLElement | 'parent'>('parent');

    useEffect(() => {
      setAppendTarget(document.body ?? 'parent');
      setIsIOS(/iPhone|iPad|iPod/.test(navigator.platform));
    }, []);

    return (
      <Tippy
        appendTo={appendTarget}
        interactive
        followCursor={followCursor}
        plugins={[followCursorPlugin]}
        popperOptions={{
          modifiers: [
            { name: 'arrow', enabled: false },
            ...(size === 'small'
              ? [
                  {
                    name: 'offset',
                    options: {
                      offset: [0, 4],
                    },
                  },
                ]
              : []),
          ],
        }}
        placement={placement}
        onShow={() => setShowTooltip(true)}
        onHide={() => setShowTooltip(false)}
        onClickOutside={() => setShowTooltip(false)}
        visible={typeof delay !== 'undefined' ? undefined : showTooltip} // delay option doesn't work with visible option
        delay={delay}
        render={(tippyProps) =>
          showTooltip &&
          children && (
            <TooltipContainer
              maxWidth={maxWidth}
              ref={innerRef}
              variant={variant}
              size={size}
              {...props}
              {...tippyProps}
            >
              {children}
            </TooltipContainer>
          )
        }
        triggerTarget={isIOS ? null : undefined}
        touch={disableTouch}
      >
        <TooltipOpener className={className} {...triggerProps}>
          {opener({
            isOpen: showTooltip,
          })}
        </TooltipOpener>
      </Tippy>
    );
  },
);

Tooltip.displayName = 'Tooltip';

export default Tooltip;
