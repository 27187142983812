// @ts-nocheck
import styled, { css, FlattenSimpleInterpolation } from "styled-components";
import { ellipsis as polishedEllipsis } from "polished";

import { TypographyProps } from "./types";

export const TypographyWrapper = styled.div<TypographyProps>`
  font-family: ${({ theme }) => theme.mosaiq.fonts.main};
  color: ${({ theme, color }): FlattenSimpleInterpolation =>
    theme.mosaiq.colors[color as string] ?? color ?? "inherit"};

  ${({ whiteSpace }): FlattenSimpleInterpolation | undefined =>
    !!whiteSpace
      ? css`
          white-space: ${whiteSpace};
        `
      : undefined};

  ${({ theme, variant, fontWeight, ellipsis }): FlattenSimpleInterpolation =>
    theme.mosaiq.typography[variant as string]({
      fontWeight,
      singleLine: ellipsis?.lines === 1,
    })};
  ${({ wordBreak }): FlattenSimpleInterpolation | undefined =>
    !!wordBreak
      ? css`
          word-break: ${wordBreak};
          hyphens: auto;
        `
      : undefined};
  ${({ ellipsis }): FlattenSimpleInterpolation | undefined =>
    !!ellipsis && !!Object.keys(ellipsis).length
      ? css`
          ${polishedEllipsis(ellipsis.width, ellipsis.lines)};
        `
      : undefined};
  ${({ italic }): FlattenSimpleInterpolation | undefined =>
    !!italic
      ? css`
          font-style: italic;
        `
      : undefined};
  ${({ textAlign }): FlattenSimpleInterpolation | undefined =>
    !!textAlign
      ? css`
          text-align: ${textAlign};
        `
      : undefined};
  ${({ textTransform }): FlattenSimpleInterpolation | undefined =>
    !!textTransform
      ? css`
          text-transform: ${textTransform};
        `
      : undefined};
  ${({ textDecoration }): FlattenSimpleInterpolation | undefined =>
    !!textDecoration
      ? css`
          text-decoration: ${textDecoration};
        `
      : undefined};
  ${({ link }): FlattenSimpleInterpolation | undefined =>
    !!link
      ? css`
          text-decoration: underline;
          cursor: pointer;
        `
      : undefined};
`;
