import React, { useState } from 'react';

import { mosaiqStyled } from '../../lib';
import { DropdownArrow } from '../../base/Field';

import { AccordionProps } from './types';

import {
  AccordionContainer,
  AccordionItemContainer,
  AccordionItemSummary,
  AccordionItemContent,
} from './Accordion.styles';

function getDefaultOpenIndicies(
  defaultOpen: AccordionProps['defaultOpen'],
  exclusive: AccordionProps['exclusive'],
  items: AccordionProps['items'],
): number[] {
  return typeof defaultOpen === 'number'
    ? [defaultOpen]
    : Array.isArray(defaultOpen)
    ? defaultOpen
    : defaultOpen === true
    ? exclusive
      ? [0]
      : items.map((_, i) => i)
    : [];
}

const Accordion = mosaiqStyled<AccordionProps, HTMLDivElement>(
  ({ items, exclusive = false, defaultOpen, innerRef, ...rest }) => {
    const [openItems, setOpenItems] = useState<number[]>(
      getDefaultOpenIndicies(defaultOpen, exclusive, items),
    );

    const toggleItem = (index: number, e: React.MouseEvent) => {
      e.preventDefault();

      if (openItems.includes(index)) {
        if (exclusive) {
          setOpenItems([]);
        } else {
          setOpenItems(openItems.filter((i) => i !== index));
        }
      } else {
        if (exclusive) {
          setOpenItems([index]);
        } else {
          setOpenItems([...openItems, index]);
        }
      }
    };

    return (
      <AccordionContainer ref={innerRef} {...rest}>
        {items.map((item, i) => {
          const open = exclusive ? i === openItems[0] : openItems.includes(i);
          return (
            <AccordionItemContainer key={i} open={open} onClick={toggleItem.bind(null, i)}>
              <AccordionItemSummary>
                {item.summary}
                {!!item.content && <DropdownArrow open={open} />}
              </AccordionItemSummary>
              <AccordionItemContent open={open} onClick={(e) => e.stopPropagation()}>
                <div>{item.content}</div>
              </AccordionItemContent>
            </AccordionItemContainer>
          );
        })}
      </AccordionContainer>
    );
  },
);

export default Accordion;
