import React from 'react';

import { mosaiqStyled } from '../../lib';

import { FabProps } from './types';

import { FabContainer } from './FloatingActionButton.styles';

const FloatingActionButton = mosaiqStyled<FabProps, HTMLButtonElement>(
  ({ children, innerRef, ...props }) => {
    return (
      <FabContainer ref={innerRef} {...props}>
        {children}
      </FabContainer>
    );
  },
);

FloatingActionButton.displayName = 'Fab';

export default FloatingActionButton;
