import styled from "styled-components";
import { rgba } from "polished";
import { Flex, Typography } from "@/components/ui";

import config from "@/common/config";

export const NavigationContainer = styled.div<{ active: boolean }>`
  width: 100%;
  position: fixed;
  top: 0;
  z-index: 10;
  box-shadow: ${({ theme }) => theme.mosaiq.elevation[2]};

  &:before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    background: ${({ theme, active }) =>
      active
        ? theme.mosaiq.colors.white
        : rgba(theme.mosaiq.colors.black, 0.8)};
    backdrop-filter: blur(8px);
    transition: background 0.3s ease-out;
  }
`;

export const NavigationWrapper = styled(Flex).attrs({ alignItems: "center" })`
  width: 100%;
  max-width: ${config.appConfig.appWrapperWidth}px;
  margin: 0 auto;
  height: ${config.appConfig.appNavigationHeight}px;
  position: relative;
  z-index: 1;

  ${({ theme }) => theme.mosaiq.mediaQueries.screenSize({
    max: "extraSmall",
  })`
    height: ${config.appConfig.appNavigationHeightMobile}px;
  `}
`;

export const AppTypographyLogo = styled(Typography)`
  letter-spacing: -0.5px;
`;
