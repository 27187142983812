import React, { useState, useEffect } from 'react';

import { mosaiqStyled } from '../../lib';
import { Typography } from '../Typography';

import { SwitchProps } from './types';
import { SwitchContainer, SwitchInput, SwitchTextContainer, SwitchThumb } from './Switch.styles';

const Switch = mosaiqStyled<SwitchProps, HTMLLabelElement, HTMLInputElement>(
  ({
    active,
    disabled = false,
    label,
    helperText,
    innerRef,
    onChange,
    inputProps,
    autoFocus = false,
    inline = true,
    startAdornment,
    endAdornment,
    ...props
  }) => {
    const [isActive, setActive] = useState<boolean>(!!active);

    useEffect(() => setActive(!!active), [active]);

    return (
      <SwitchContainer active={isActive} disabled={disabled} hasLabel={!!label} {...props}>
        <SwitchInput>
          <SwitchThumb />
          <input
            type="checkbox"
            disabled={disabled}
            autoFocus={autoFocus}
            {...inputProps}
            ref={innerRef}
            onChange={(e) => {
              if (active === undefined) setActive(e.target.checked);
              onChange?.(e);
            }}
            checked={isActive}
          />
        </SwitchInput>

        {!!startAdornment && startAdornment}

        {(!!label || !!helperText) && (
          <SwitchTextContainer inline={inline}>
            {!!label && (
              <Typography
                ellipsis={{ lines: 1 }}
                marginLeft={1.5}
                variant="body2"
                color={disabled ? 'placeholder' : 'body'}
                fontWeight={500}
              >
                {label}
              </Typography>
            )}
            {!!helperText && (
              <Typography
                ellipsis={{ lines: 1 }}
                marginLeft={1.5}
                variant="body4"
                color={disabled ? 'placeholder' : 'passive'}
                italic
              >
                {helperText}
              </Typography>
            )}
          </SwitchTextContainer>
        )}

        {!!endAdornment && endAdornment}
      </SwitchContainer>
    );
  },
);

export default Switch;
