import styled, { css } from 'styled-components';
import { X } from 'phosphor-react';
import { Color } from '../../theme';
import { Typography } from '../Typography';

interface PillStyleProps {
  activeRemove?: boolean;
  hasAvatar: boolean;
  hasRemove: boolean;
  wrap?: boolean;
  background?: Color;
  color?: Color;
}

export const PillRemoveIcon = styled(X)`
  max-width: 0px;
  max-height: 0px;
  min-width: 0px;
  min-height: 0px;
  opacity: 0;
  visibility: hidden;
`;

export const PillWrapper = styled.div<PillStyleProps>`
  display: inline-flex;
  align-items: center;
  ${({ theme, wrap }) => !wrap && `height: ${theme.spacing(2.5)}px;`}
  border-radius: ${({ theme }) => `${theme.spacing(0.5)}px`};
  background-color: ${({ theme, background }) =>
    background ? theme.mosaiq.colors[background] : theme.mosaiq.colors.white};
  color: ${({ theme, color }) => (color ? theme.mosaiq.colors[color] : theme.mosaiq.colors.body)};
  max-width: 100%;
  border: 1px solid transparent;

  ${({ hasAvatar, theme }) =>
    css`
      padding: ${theme.spacing(0.5)}px ${theme.spacing(0.75)}px ${theme.spacing(0.5)}px
        ${theme.spacing(hasAvatar ? 0.5 : 1)}px;
    `}

  ${({ activeRemove, theme }) =>
    activeRemove &&
    css`
      padding-right: ${theme.spacing(0.5)}px;

      ${PillRemoveIcon} {
        max-width: ${({ theme }) => theme.spacing(1.5)}px;
        max-height: ${({ theme }) => theme.spacing(1.5)}px;
        min-width: ${({ theme }) => theme.spacing(1.5)}px;
        min-height: ${({ theme }) => theme.spacing(1.5)}px;
        opacity: 1;
        visibility: visible;
        margin-left: ${theme.spacing(0.5)}px;
        cursor: pointer;
      }
    `}

  ${({ onClick, hasRemove, theme }) =>
    (onClick || hasRemove) &&
    css`
      &:hover {
        border: 1px solid ${theme.mosaiq.colors.placeholder};

        ${hasRemove &&
        css`
          padding-right: ${theme.spacing(0.5)}px;
        `}

        ${PillRemoveIcon} {
          max-width: ${({ theme }) => theme.spacing(1.5)}px;
          max-height: ${({ theme }) => theme.spacing(1.5)}px;
          min-width: ${({ theme }) => theme.spacing(1.5)}px;
          min-height: ${({ theme }) => theme.spacing(1.5)}px;
          opacity: 1;
          visibility: visible;
          margin-left: ${theme.spacing(0.5)}px;
          cursor: pointer;
        }
      }

      &:active {
        border: 1px solid ${theme.mosaiq.colors.placeholder};
        background-color: ${theme.mosaiq.colors.grey};
      }
    `}

    ${({ wrap }) =>
    wrap &&
    css`
      > ${Typography} {
        line-height: 1.2;
      }
    `}
`;
