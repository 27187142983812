import React, { SVGAttributes } from 'react';
import { useTheme } from 'styled-components';
import { ArrowBendDownRight } from 'phosphor-react';

import { mosaiqStyled } from '../../lib';

const TableDepthIndicator = mosaiqStyled<SVGAttributes<SVGSVGElement>, SVGSVGElement>(
  ({ innerRef, ...props }) => {
    const theme = useTheme();

    return (
      <ArrowBendDownRight {...props} ref={innerRef} size={24} color={theme.mosaiq.colors.grey60} />
    );
  },
);

TableDepthIndicator.displayName = 'TableDepthIndicator';

export default TableDepthIndicator;
