import { FC, PropsWithChildren } from "react";
import { Box, BoxProps, Flex } from "@/components/ui";

import config from "@/common/config";
import { useIsExtraSmallOnly } from "@/hooks/useMediaQueries";

interface ContentBoxProps {
  fullWidth?: boolean;
  maxWidth?: string | number;
}

const ContentBox: FC<PropsWithChildren<ContentBoxProps>> = ({
  children,
  fullWidth,
  maxWidth,
  ...rest
}) => {
  const isMobileView = useIsExtraSmallOnly();

  return (
    <Box
      maxWidth={
        fullWidth ? "none" : maxWidth ?? config.appConfig.appWrapperWidth / 8
      }
      width={isMobileView ? "100%" : config.appConfig.appWrapperWidth / 8}
      padding={isMobileView ? [0, 3] : 0}
      style={{ margin: "0 auto" }}
      {...rest}
    >
      {children}
    </Box>
  );
};

export default ContentBox;
