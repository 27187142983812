import styled from 'styled-components';

export const ListPopoverOpener = styled.div`
  cursor: pointer;
  width: max-content;
`;

export const ListHeader = styled.div`
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1;
`;

export const ListFooter = styled.div`
  position: sticky;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;
`;
