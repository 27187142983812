import styled, { css } from 'styled-components';
import { ControlContainer, ControlInput } from '../../base/Control';

import { CheckboxProps } from './types';

export const CheckboxInput = ControlInput;

export const CheckboxContainer = styled(ControlContainer)<
  CheckboxProps & {
    hasLabel: boolean;
  }
>`
  gap: ${({ theme }) => theme.mosaiq.spacing(1)}px;

  ${CheckboxInput} {
    ${({ checked, disabled, hasLabel, theme, indeterminate }) => {
      if (disabled) {
        if (checked || indeterminate) {
          return css`
            background-color: ${theme.mosaiq.colors.grey60};
            border: thin solid ${theme.mosaiq.colors.grey60};

            svg {
              color: ${({ theme }) => theme.mosaiq.colors.passive};
            }
          `;
        }

        return css`
          background-color: ${theme.mosaiq.colors.grey};
          border: thin solid ${theme.mosaiq.colors.grey40};
        `;
      }

      if (checked || indeterminate) {
        return css`
          background-color: ${theme.mosaiq.colors.black};
          border: thin solid ${theme.mosaiq.colors.black};

          ${!hasLabel &&
          css`
            &:hover {
              background-color: ${theme.mosaiq.colors.passive};
              border: thin solid ${theme.mosaiq.colors.passive};
            }
          `}
        `;
      }

      return css`
        background-color: ${theme.mosaiq.colors.white};
        border: thin solid ${theme.mosaiq.colors.grey60};

        ${!hasLabel &&
        css`
          &:hover {
            border: thin solid ${theme.mosaiq.colors.passive};
          }
        `}
      `;
    }}
  }
`;

export const CheckboxTextContainer = styled.div<{ inline?: boolean }>`
  display: flex;
  width: 100%;
  align-items: center;
  column-gap: ${({ theme }) => theme.mosaiq.spacing(1.5)}px;

  ${({ inline }) =>
    !inline &&
    css`
      flex-wrap: wrap;
      flex-direction: column;
      align-items: flex-start;
    `};
`;
