import { Flex, IconButton, Input } from "@/components/ui";
import styled, { css } from "styled-components";

export const WaitlistFormWrapper = styled(Flex)`
  position: relative;

  .loader {
    width: 50px;
    aspect-ratio: 1;
    border-radius: 50%;
    border: 8px solid #fff;
    animation:
      l20-1 0.8s infinite linear alternate,
      l20-2 1.6s infinite linear;
  }
  @keyframes l20-1 {
    0% {
      clip-path: polygon(50% 50%, 0 0, 50% 0%, 50% 0%, 50% 0%, 50% 0%, 50% 0%);
    }
    12.5% {
      clip-path: polygon(
        50% 50%,
        0 0,
        50% 0%,
        100% 0%,
        100% 0%,
        100% 0%,
        100% 0%
      );
    }
    25% {
      clip-path: polygon(
        50% 50%,
        0 0,
        50% 0%,
        100% 0%,
        100% 100%,
        100% 100%,
        100% 100%
      );
    }
    50% {
      clip-path: polygon(
        50% 50%,
        0 0,
        50% 0%,
        100% 0%,
        100% 100%,
        50% 100%,
        0% 100%
      );
    }
    62.5% {
      clip-path: polygon(
        50% 50%,
        100% 0,
        100% 0%,
        100% 0%,
        100% 100%,
        50% 100%,
        0% 100%
      );
    }
    75% {
      clip-path: polygon(
        50% 50%,
        100% 100%,
        100% 100%,
        100% 100%,
        100% 100%,
        50% 100%,
        0% 100%
      );
    }
    100% {
      clip-path: polygon(
        50% 50%,
        50% 100%,
        50% 100%,
        50% 100%,
        50% 100%,
        50% 100%,
        0% 100%
      );
    }
  }
  @keyframes l20-2 {
    0% {
      transform: scaleY(1) rotate(0deg);
    }
    49.99% {
      transform: scaleY(1) rotate(135deg);
    }
    50% {
      transform: scaleY(-1) rotate(0deg);
    }
    100% {
      transform: scaleY(-1) rotate(-135deg);
    }
  }

  ${({ theme }) => theme.mosaiq.mediaQueries.screenSize({
    max: "extraSmall",
  })`
    .loader {
      width: 30px;
    }
  `}
`;

export const WaitlistInput = styled(Input)`
  > div[disabled] {
    background: ${({ theme }) => theme.mosaiq.colors.emerald};
    border: 1px solid ${({ theme }) => theme.mosaiq.colors.emerald};
    transition: all 0.3s ease-out;

    input {
      &::placeholder {
        color: ${({ theme }) => theme.mosaiq.colors.white};
      }
    }
  }

  > div {
    padding: 42px 30px;
    font-size: 24px;
    border-radius: 42px;

    input {
      font-size: 28px;
      font-weight: 400;
      color: ${({ theme }) => theme.mosaiq.colors.black};
      letter-spacing: -0.5px;

      &::placeholder {
        font-weight: 300;
        letter-spacing: 0px;
        color: ${({ theme }) => theme.mosaiq.colors.grey80};
      }
    }
  }

  ${({ theme }) => theme.mosaiq.mediaQueries.screenSize({
    max: "extraSmall",
  })`
  > div {
    padding: 28px 16px;
    font-size: 16px;
    border-radius: 32px;

    input {
      font-size: 16px;
      font-weight: 300;
      letter-spacing: 0px;
    }
  }
  `}
`;

export const WaitlistFormSubmit = styled(IconButton)`
  min-height: 64px;
  max-height: 64px;
  min-width: 64px;
  max-width: 64px;
  border-radius: 32px;

  position: absolute;
  right: 10px;
  top: 10px;

  svg {
    min-height: 32px;
    max-height: 32px;
    min-width: 32px;
    max-width: 32px;
  }

  ${({ theme }) => theme.mosaiq.mediaQueries.screenSize({
    max: "extraSmall",
  })`
    min-height: 44px;
    max-height: 44px;
    min-width: 44px;
    max-width: 44px;
    border-radius: 44px;

    right: 8px;
    top: 6px;

    svg {
      min-height: 24px;
      max-height: 24px;
      min-width: 24px;
      max-width: 24px;
    }
  `}
`;
