import styled, { keyframes } from 'styled-components';

import { selfOrFunction } from '../../utils';
import { SpinnerIconProps } from './types';

const loading = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

export const SpinnerIcon = styled.div<SpinnerIconProps>`
  width: ${({ theme, $size }) => theme.spacing($size)}px;
  height: ${({ theme, $size }) => theme.spacing($size)}px;
  border: ${({ theme, $size, $color }) =>
    `${theme.spacing($size) * ($size <= 5 ? 0.1 : 0.05)}px solid ${selfOrFunction(
      { theme },
      $color,
    )}`};
  border-top-color: transparent;
  border-radius: 50%;
  animation: ${loading} 0.5208333333s linear infinite;
`;
