import styled from 'styled-components';

export const GridContainer = styled.div<{ $columns: number }>`
  display: grid;
  grid-template-columns: repeat(${({ $columns }) => $columns}, 1fr);
  border: 1px solid ${({ theme }) => theme.mosaiq.colors.grey60};
  border-radius: ${({ theme }) => theme.spacing(2)}px;

  & > *:not(*:last-child) {
    border-right: 1px solid ${({ theme }) => theme.mosaiq.colors.grey60};
  }
`;
