import styled, { css } from 'styled-components';

export const MenuCard = styled.div`
  box-shadow: ${({ theme }) => theme.mosaiq.elevation[2]};
  border-radius: ${({ theme }) => theme.mosaiq.spacing(1.5)}px;
  border: 1px solid ${({ theme }) => theme.mosaiq.colors.grey20};
  width: 100%;
  overflow: auto;
  background-color: ${({ theme }) => theme.mosaiq.colors.white};

  ::-webkit-scrollbar {
    width: ${({ theme }) => theme.mosaiq.spacing(2)}px;
  }

  ::-webkit-scrollbar-thumb {
    border: ${({ theme }) => theme.mosaiq.spacing(0.5)}px solid rgba(0, 0, 0, 0);
    background-clip: padding-box;
    border-radius: 9999px;
    background-color: ${({ theme }) => theme.mosaiq.colors.grey20};
  }
`;

export const MenuContainer = styled.div<{
  maxHeight: number;
  maxWidth?: number;
  minWidth?: number;
  hasShimmer: boolean;
}>`
  position: relative;
  width: max-content;
  max-width: ${({ maxWidth }) => maxWidth && `${maxWidth}px`};
  min-width: ${({ minWidth }) => minWidth && `${minWidth}px`};
  outline: none;
  width: 100%;

  ${MenuCard} {
    max-height: ${({ maxHeight }) => maxHeight}px;
    max-width: ${({ maxWidth }) => maxWidth && `${maxWidth}px`};

    ${({ theme, hasShimmer }) =>
      hasShimmer
        ? css`
            padding: ${theme.mosaiq.spacing(1)}px ${theme.mosaiq.spacing(1)}px
              ${theme.mosaiq.spacing(4)}px;
          `
        : css`
            padding: ${theme.mosaiq.spacing(1)}px;
          `}
  }
`;

export const MenuShimmer = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.98) 75%);
  height: ${({ theme }) => theme.mosaiq.spacing(6)}px;
  border-radius: ${({ theme }) =>
    `0px 0px ${theme.mosaiq.spacing(1.5)}px ${theme.mosaiq.spacing(1.5)}px`};
  z-index: 10;
  pointer-events: none;
`;
