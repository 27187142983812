import React from 'react';

import { mosaiqStyled } from '../../lib';

import NativeFlex from './NativeFlex';
import NonnativeFlex from './NonnativeFlex';
import { FlexProps } from './types';
import { getBrowserInfo } from './utils';

let isFlexGapSupported = false;

const browser = getBrowserInfo();

if (!(browser instanceof Error)) {
  const browserName = browser.name;
  const browserVersion = parseFloat(browser.version ?? '');

  isFlexGapSupported =
    !!window?.CSS?.supports?.('gap', '0px') &&
    !(
      (browserName === 'safari' && browserVersion < 14.1) ||
      (browserName === 'opera' && browserVersion < 70)
    );
}

const Flex = mosaiqStyled<FlexProps>(({ wrap = 'nowrap', gap = 0, inline = false, as, ...props }) =>
  wrap === 'nowrap' || isFlexGapSupported ? (
    <NativeFlex
      forwardedAs={as}
      gap={gap}
      wrap={wrap}
      isNative={isFlexGapSupported}
      inline={inline}
      {...props}
    />
  ) : (
    <NonnativeFlex forwardedAs={as} gap={gap} wrap={wrap} inline={inline} {...props} />
  ),
);

Flex.displayName = 'Flex';

export default Flex;
