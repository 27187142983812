// @ts-nocheck
import React from "react";

import { mosaiqStyled } from "../../lib";

import { ButtonProps } from "./types";

import { ButtonContainer } from "./Button.styles";

const Button = mosaiqStyled<ButtonProps, HTMLButtonElement>(
  ({ children, endAdornment, startAdornment, innerRef, ...props }) => {
    return (
      <ButtonContainer
        type="button"
        ref={innerRef}
        startAdornment={startAdornment}
        endAdornment={endAdornment}
        {...props}
      >
        {startAdornment}
        <span>{children}</span>
        {endAdornment}
      </ButtonContainer>
    );
  }
);

Button.displayName = "Button";

export default Button;
