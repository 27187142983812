import styled, { css } from 'styled-components';

import { Typography } from '../Typography';

export const SelectionPillContainer = styled.div<{ selected: boolean }>`
  display: inline-flex;
  align-items: center;
  border: 1px solid ${({ theme }) => theme.mosaiq.colors.placeholder};
  border-radius: ${({ theme }) => theme.mosaiq.spacing(3.5)}px;
  color: ${({ theme }) => theme.mosaiq.colors.title};
  cursor: pointer;
  padding: ${({ theme }) => theme.mosaiq.spacing(1.5)}px;

  ${Typography} {
    line-height: 1;
  }

  ${({ selected, theme }) =>
    !!selected
      ? css`
          background-color: ${theme.mosaiq.colors.black};
          border-color: ${theme.mosaiq.colors.black};
          color: ${theme.mosaiq.colors.white};
        `
      : css`
          &:hover {
            background-color: ${({ theme }) => theme.mosaiq.colors.grey20};
            border-color: ${({ theme }) => theme.mosaiq.colors.grey20};
          }
        `};
`;
