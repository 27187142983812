import React, { ReactNode, FC, useMemo } from 'react';
import { ThemeProvider, ThemedStyledProps, DefaultTheme } from 'styled-components';
import merge from 'lodash/merge';

import breakpoints from './breakpoints';
import colors from './colors';
import dimensions from './dimensions';
import mediaQueries from './mediaQueries';
import mixins from './mixins';
import typography from './typography';
import zIndexes from './zIndexes';

export const mosaiqTheme = {
  breakpoints,
  colors,
  dimensions,
  fonts: {
    main: "'Aeonik', sans-serif",
  },
  spacing: (x: number): number => x * 8,
  elevation: {
    1: '0px 1px 3px rgba(0, 0, 0, 0.1)',
    2: '0px 0px 10px rgba(0, 0, 0, 0.05), 0px 5px 10px rgba(0, 0, 0, 0.05), 0px 2px 4px rgba(0, 0, 0, 0.05), 0px 10px 20px rgba(0, 0, 0, 0.05)',
  },
  mediaQueries,
  mixins,
  typography,
  zIndexes,
};

export type Color = keyof typeof colors;

export type StyledFunction<T, P extends object = Record<string, unknown>> = (
  props: ThemedStyledProps<P, DefaultTheme>,
) => T;

export interface ThemeProviderPropsWithExtras {
  children?: ReactNode;
  extras?: Record<string, unknown>;
}

export const MosaiqThemeProvider: FC<ThemeProviderPropsWithExtras> = ({
  children,
  extras,
}: ThemeProviderPropsWithExtras): JSX.Element => {
  const theme = useMemo(() => merge(mosaiqTheme, extras), [extras]);

  // TODO: remove mosaiq key once toblerone-ui is depecrated
  return <ThemeProvider theme={{ ...theme, mosaiq: mosaiqTheme }}>{children}</ThemeProvider>;
};
