export enum Layer {
  TOP_BAR = 'topBar',
  FEED = 'feed',
  NAV_SIDEBAR = 'navsidebar',
  APP_DRAWER = 'appDrawer',
  NAV_BAR = 'navbar',
  VIEW_MODAL = 'viewModal',
  SIDEBAR = 'sidebar',
  IMAGE_ZOOM = 'imagezoom',
  MODAL = 'modal',
  MEGA_MENU = 'megaMenu',
  NOTIFICATIONS_MENU = 'notificationsMenu',
  QUICK_SEARCH = 'quicksearch',
  ERROR_MODAL = 'errorModal',
  TOAST = 'toast',
  TOUR = 'tour',
}

const createLevelsObject = (levels: Layer[]) => {
  return levels.reduce<Record<Layer, number>>((acc, key: Layer, index: number) => {
    return {
      ...acc,
      [key]: (index + 1) * 100,
    };
  }, {} as Record<Layer, number>);
};

/**
 * The array order determines the z-index 'level' starting
 * at 100 and incrementing by 100 for each level.
 */
const zIndexes = createLevelsObject(Object.values(Layer));

export default zIndexes;
