import { Box, Flex, Typography } from "@/components/ui";
import WaitlistForm from "@/components/forms/WaitlistForm";
import LockIcon from "@/assets/icons/crypto-lock-4.png";
import ETLogo from "@/assets/logos/et_logo_tp.png";

import { useIsExtraSmallOnly } from "@/hooks/useMediaQueries";
import { HighlightTypography } from "@/styles/common";

import {
  HeaderContainer,
  HeaderWrapper,
  HeaderHeroText,
  HeaderTitle,
  HeaderHeroIconBox,
} from "./Header.styles";

const Header = () => {
  const isMobileView = useIsExtraSmallOnly();

  return (
    <HeaderContainer>
      {isMobileView && (
        <Flex
          justify="center"
          style={{
            position: "absolute",
            bottom: 20,
            left: "50%",
            transform: "translateX(-50%)",
          }}
        >
          <Box width={16} height={16} marginLeft={isMobileView ? 0 : -4}>
            <img src={ETLogo} alt="eternal trust logo" />
          </Box>
        </Flex>
      )}

      <HeaderWrapper direction="column" gap={4}>
        <HeaderHeroText gap={4}>
          <Box width={isMobileView ? "100%" : "48%"}>
            <Flex direction="column">
              <HeaderTitle
                variant={"title1"}
                fontWeight={500}
                color="white"
                textAlign={isMobileView ? "center" : "initial"}
              >
                Leave Behind A {isMobileView && <br />}
                {isMobileView ? (
                  <span style={{ fontSize: 56 }}>Digital Locker</span>
                ) : (
                  "Digital Locker"
                )}{" "}
                {isMobileView && <br />} For Your Family
              </HeaderTitle>

              <HighlightTypography
                variant={isMobileView ? "title4" : "title3"}
                fontWeight={300}
                color="white"
                padding={isMobileView ? [0, 2] : [0, 20, 0, 0]}
                marginTop={2}
                textAlign={isMobileView ? "center" : "initial"}
              >
                Confidentially Pass on Personal and Financial Details
              </HighlightTypography>
              <Box
                marginTop={isMobileView ? 3 : 6}
                paddingRight={isMobileView ? 0 : 15}
              >
                <WaitlistForm />
              </Box>
            </Flex>
          </Box>

          {!isMobileView && (
            <HeaderHeroIconBox height={80}>
              <img
                style={{ objectFit: "cover", textAlign: "center" }}
                src={LockIcon}
              />
            </HeaderHeroIconBox>
          )}
        </HeaderHeroText>
      </HeaderWrapper>
    </HeaderContainer>
  );
};

export default Header;
