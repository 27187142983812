import styled, { css } from 'styled-components';

import { Typography } from '../Typography';

import { TabSize } from './types';

export const TabsHeaderAdornment = styled.div<{ direction: 'start' | 'end' }>`
  position: sticky;

  ${({ direction, theme }) => {
    if (direction === 'start') {
      return css`
        left: 0;
        padding-right: ${theme.spacing(2)}px;
      `;
    }

    // end
    return css`
      right: 0;
      margin-left: auto;
      padding-left: ${theme.spacing(2)}px;
    `;
  }}
`;

export const TabsHeaderItemMeta = styled.span`
  color: ${({ theme }) => theme.mosaiq.colors.placeholder};
`;

export const TabsHeaderItem = styled.div<{
  active: boolean;
  disabled: boolean;
  size: TabSize;
  fullWidth?: boolean;
}>`
  display: flex;
  gap: ${({ theme }) => theme.mosaiq.spacing(0.75)}px;
  align-items: center;

  ${({ active }) =>
    !active &&
    css`
      cursor: pointer;
    `}

  ${({ size, theme, active }) => {
    switch (size) {
      case 'small':
      case 'large':
      case 'xlarge':
        return active
          ? css`
              color: ${theme.mosaiq.colors.title};

              ${TabsHeaderItemMeta} {
                color: ${theme.mosaiq.colors.passive};
              }
            `
          : css`
            color: ${({ theme }) => theme.mosaiq.colors.placeholder};

            &:hover {
              color: ${({ theme }) => theme.mosaiq.colors.passive};
            }
          }
        `;

      default:
        return css`
          padding: ${theme.mosaiq.spacing(1)}px ${theme.mosaiq.spacing(1.25)}px;
          ${({ theme }) => theme.mosaiq.mixins.squircle({ borderRadius: theme.mosaiq.spacing(1) })};

          ${active
            ? css`
                color: ${theme.mosaiq.colors.title};
                background-color: ${({ theme }) => theme.mosaiq.colors.grey};

                ${TabsHeaderItemMeta} {
                  color: ${theme.mosaiq.colors.passive};
                }
              `
            : css`
                color: ${theme.mosaiq.colors.body};
              `}

          &:hover {
            background-color: ${({ theme }) => theme.mosaiq.colors.grey20};
          }
        `;
    }
  }}

  ${({ disabled, theme }) =>
    disabled &&
    css`
      color: ${theme.mosaiq.colors.placeholder};
      pointer-events: none;
    `}

    & svg {
    color: ${({ theme }) => theme.mosaiq.colors.passive};
  }

  ${({ fullWidth }) =>
    fullWidth &&
    css`
      width: 100%;
      position: relative;
      padding: 0;
      & > span {
        display: block;
        width: 100%;
      }
    `}
`;

export const TabsHeader = styled.div<{
  size: TabSize;
  collapsible?: boolean;
  isCollapsed?: boolean;
}>`
  display: flex;
  align-items: center;
  overflow-x: ${({ collapsible }) => (collapsible ? 'hidden' : 'auto')};
  padding-right: ${({ theme, isCollapsed }) => (isCollapsed ? theme.mosaiq.spacing(5) : 0)}px;

  &::-webkit-scrollbar {
    width: 0px;
    height: 0px;
  }

  & > ${Typography} {
    min-width: max-content;

    &:not(:last-of-type) {
      margin-right: ${({ size, theme }) => {
        switch (size) {
          case 'xlarge':
            return theme.mosaiq.spacing(4);

          case 'large':
            return theme.mosaiq.spacing(3);

          // small and regular
          default:
            return theme.mosaiq.spacing(2);
        }
      }}px;
    }
  }

  & .hidden {
    opacity: 0;
    pointer-events: none;
    order: 99;
  }
`;
