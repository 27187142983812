import { useTheme } from "styled-components";
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";

import ContentBox from "@/components/layout/ContentBox";
import { Box, Flex, Typography } from "@/components/ui";
import { useIsExtraSmallOnly } from "@/hooks/useMediaQueries";

import { VerticalTimelineWrapper } from "./HowItWorks.styles";

const HowItWorks = () => {
  const theme = useTheme();
  const isMobileView = useIsExtraSmallOnly();

  return (
    <Box backgroundColor="white" padding={isMobileView ? [4, 0, 10] : [10, 0]}>
      <ContentBox>
        <Typography
          variant={isMobileView ? "title2" : "title1"}
          style={{ letterSpacing: -1, lineHeight: "56px" }}
          marginBottom={isMobileView ? 4 : 12}
          textAlign={isMobileView ? "left" : "center"}
        >
          How it works
        </Typography>

        <VerticalTimelineWrapper>
          <VerticalTimeline
            layout={isMobileView ? "1-column-left" : "2-columns"}
            lineColor={theme.mosaiq.colors.title}
          >
            <VerticalTimelineElement>
              <Flex direction="column" gap={0.5}>
                <Typography variant={"body1"}>
                  Your Notes & Documents
                </Typography>
                <Typography variant="body2" fontWeight={300}>
                  Leave behind messages for your family, such as personal notes,
                  financial & cryptocurrency information, memories,
                  instructions, or anything else you wish to convey.
                </Typography>
              </Flex>
            </VerticalTimelineElement>
            <VerticalTimelineElement>
              <Flex direction="column" gap={0.5}>
                <Typography variant={"body1"}>Your data, Your rules</Typography>
                <Typography variant="body2" fontWeight={300}>
                  We offer a variety of securty options. Our smart use of
                  SHA-256 encryption, combined with you being the only one with
                  access to the key of the digital locker, makes it
                  impenetrable. Nobody, not even us, have access to your
                  information.
                </Typography>
              </Flex>
            </VerticalTimelineElement>
            <VerticalTimelineElement>
              <Flex direction="column" gap={0.5}>
                <Typography variant={"body1"}>Your Nominee</Typography>
                <Typography variant="body2" fontWeight={300}>
                  Think of a person you trust to receive your important
                  information after you're gone. This could be a family member,
                  friend, or trusted advisor.
                </Typography>
              </Flex>
            </VerticalTimelineElement>
            <VerticalTimelineElement>
              <Flex direction="column" gap={0.5}>
                <Typography variant={"body1"}>Regular Check-Ins</Typography>
                <Typography variant="body2" fontWeight={300}>
                  Our system initiates regular check-ins with users to confirm
                  their well-being. Users receive periodic prompts, emails and
                  sms. You can set the rules.
                </Typography>
              </Flex>
            </VerticalTimelineElement>
            <VerticalTimelineElement>
              <Flex direction="column" gap={0.5}>
                <Typography variant={"body1"}>Nominee Notification</Typography>
                <Typography variant="body2" fontWeight={300}>
                  In the unfortunate event of a user's passing, we automatically
                  notify their designated nominee via email and message. The
                  nominee will receive detailed instructions on securely
                  accessing the digital locker.
                </Typography>
              </Flex>
            </VerticalTimelineElement>
            <VerticalTimelineElement>
              <Flex direction="column" gap={0.5}>
                <Typography variant={"body1"}>Security Verification</Typography>
                <Typography variant="body2" fontWeight={300}>
                  We will verify nominee access with security protocol of user's
                  choice and additionally, user can opt for a death certificate
                  security layer, assuring the right nominee's access.
                </Typography>
              </Flex>
            </VerticalTimelineElement>
          </VerticalTimeline>
        </VerticalTimelineWrapper>

        {!isMobileView && <Flex marginBottom={12} />}
      </ContentBox>
    </Box>
  );
};

export default HowItWorks;
