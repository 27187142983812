import { useEffect, useState } from "react";
import debounce from "lodash/debounce";
import { Box, Flex, Typography } from "@/components/ui";
import type { FunctionComponent } from "@/common/types";
import { HighlightTypography } from "@/styles/common";

import ContentBox from "@/components/layout/ContentBox";
import Footer from "@/components/layout/Footer";
import Header from "@/components/layout/Header";
import HowItWorks from "@/components/layout/HowItWorks";
import Navigation from "@/components/layout/Navigation";
import UserStories from "@/components/layout/UserStories";
import WaitlistForm from "@/components/forms/WaitlistForm";

import IconSoc2 from "@/assets/icons/logo-soc2.png";
import IconAes from "@/assets/icons/logo-aes.png";
import IconGdpr from "@/assets/icons/logo-gdpr.png";
import IconFinances from "@/assets/backgrounds/flawed_finances.png";
import IconLifeIsUncertain from "@/assets/backgrounds/life_is_uncertain.png";

import { analytics, logEvent } from "@/common/firebaseApp";
import { useIsExtraSmallOnly } from "@/hooks/useMediaQueries";

import { FullImageBox, HomeWrapper } from "./Home.styles";

const Home = (): FunctionComponent => {
  const isMobileView = useIsExtraSmallOnly();
  const [scrollLogs, setScrollLogs] = useState({
    scroll25: false,
    scroll50: false,
    scroll75: false,
    scroll100: false,
  });

  useEffect(() => {
    if (window.location.hostname === "localhost") return;

    const windowHeight = document.body.offsetHeight;
    const windowHeight25 = (25 / 100) * windowHeight;
    const windowHeight50 = (50 / 100) * windowHeight;
    const windowHeight75 = (75 / 100) * windowHeight;

    const onScrollHandler = debounce(() => {
      if (window.scrollY >= windowHeight && !scrollLogs.scroll100) {
        logEvent(analytics, "scroll_page_100", { isMobile: isMobileView });
        setScrollLogs({
          ...scrollLogs,
          scroll100: true,
        });
      } else if (window.scrollY >= windowHeight75 && !scrollLogs.scroll75) {
        logEvent(analytics, "scroll_page_75", { isMobile: isMobileView });
        setScrollLogs({
          ...scrollLogs,
          scroll75: true,
        });
      } else if (window.scrollY >= windowHeight50 && !scrollLogs.scroll50) {
        logEvent(analytics, "scroll_page_50", { isMobile: isMobileView });
        setScrollLogs({
          ...scrollLogs,
          scroll50: true,
        });
      } else if (window.scrollY >= windowHeight25 && !scrollLogs.scroll25) {
        logEvent(analytics, "scroll_page_25", { isMobile: isMobileView });
        setScrollLogs({
          ...scrollLogs,
          scroll25: true,
        });
      }
    }, 200);

    window.addEventListener("scroll", onScrollHandler);

    return () => {
      window.removeEventListener("scroll", onScrollHandler);
    };
  }, [scrollLogs]);

  return (
    <HomeWrapper>
      <Navigation />
      <Header />

      <Box backgroundColor="white" padding={isMobileView ? [15, 0] : [20, 0]}>
        <ContentBox>
          <Flex direction="column" gap={isMobileView ? 12 : 1}>
            <Flex
              gap={isMobileView ? 3 : 1}
              alignItems={isMobileView ? "flex-start" : "center"}
              direction={isMobileView ? "column" : "row"}
            >
              <FullImageBox
                {...(isMobileView
                  ? { width: "100%", borderRadius: 4 }
                  : {
                      backgroundColor: ({ theme }) => theme.mosaiq.colors.white,
                      borderRadius: 4,
                      padding: 8,
                      width: 60,
                      height: 50,
                    })}
              >
                <img src={IconLifeIsUncertain} />
              </FullImageBox>

              <Box
                {...(isMobileView
                  ? { width: "100%" }
                  : {
                      backgroundColor: ({ theme }) => theme.mosaiq.colors.white,
                      borderRadius: 5,
                      padding: 8,
                      width: "70%",
                      height: 50,
                    })}
              >
                <Flex
                  justify="center"
                  direction="column"
                  gap={2}
                  padding={0}
                  style={{ height: "100%" }}
                >
                  <HighlightTypography
                    variant={isMobileView ? "title2" : "title2"}
                    fontWeight={300}
                    style={{
                      letterSpacing: -1,
                      lineHeight: isMobileView ? "56px" : "56px",
                    }}
                  >
                    Life is <mark className="highlight black">uncertain</mark>{" "}
                  </HighlightTypography>
                  <HighlightTypography
                    variant={isMobileView ? "title5" : "title4"}
                    color="body"
                    fontWeight={300}
                  >
                    People keep personal and financial details private; many of
                    us guard these details, reluctant to share them with others.
                  </HighlightTypography>
                  <Typography
                    variant={isMobileView ? "title5" : "title4"}
                    color="body"
                    fontWeight={300}
                  >
                    What if something unexpected were to happen to you? Where
                    would your hard-earned assets go? It's not a pleasant
                    thought, but it's a reality we must face.
                  </Typography>
                </Flex>
              </Box>
            </Flex>

            <Flex
              gap={isMobileView ? 3 : 1}
              alignItems={isMobileView ? "flex-start" : "center"}
              direction={isMobileView ? "column" : "row"}
            >
              <Box
                {...(isMobileView
                  ? { width: "100%" }
                  : {
                      backgroundColor: ({ theme }) => theme.mosaiq.colors.white,
                      borderRadius: 5,
                      padding: 8,
                      width: "70%",
                      height: 50,
                    })}
              >
                <Flex
                  justify="center"
                  direction="column"
                  gap={4}
                  padding={0}
                  style={{ height: "100%" }}
                >
                  <HighlightTypography
                    variant="title2"
                    fontWeight={300}
                    style={{ letterSpacing: -1, lineHeight: "56px" }}
                  >
                    Financial Institutions are{" "}
                    <mark className="highlight black">flawed</mark>{" "}
                  </HighlightTypography>

                  <Flex direction="column" gap={2}>
                    <HighlightTypography
                      variant={isMobileView ? "title5" : "title4"}
                      color="body"
                      fontWeight={300}
                    >
                      It's alarming to realize that over{" "}
                      <span style={{ fontWeight: 500 }}>$100,000,000,000+</span>{" "}
                      remains unclaimed worldwide. Rightful nominees fail to
                      claim it simply because they're unaware it exists.
                    </HighlightTypography>
                  </Flex>
                </Flex>
              </Box>
              <FullImageBox
                {...(isMobileView
                  ? { width: "100%", borderRadius: 4 }
                  : {
                      backgroundColor: ({ theme }) => theme.mosaiq.colors.white,
                      borderRadius: 4,
                      padding: 8,
                      width: 60,
                      height: 50,
                    })}
              >
                <img src={IconFinances} />
              </FullImageBox>
            </Flex>

            <Flex
              gap={isMobileView ? 5 : 1}
              alignItems={isMobileView ? "flex-start" : "center"}
              direction={isMobileView ? "column-reverse" : "row"}
            >
              <Box
                {...(isMobileView
                  ? { width: "100%", marginTop: 4 }
                  : {
                      backgroundColor: ({ theme }) => theme.mosaiq.colors.white,
                      borderRadius: 4,
                      padding: 8,
                      width: 60,
                      height: 50,
                    })}
              >
                <Flex
                  direction="column"
                  justify="center"
                  gap={4}
                  style={{ height: "100%" }}
                >
                  <Flex
                    gap={2}
                    direction={isMobileView ? "column" : "row"}
                    alignItems="center"
                  >
                    <img src={IconSoc2} width={72} />
                    <Flex
                      direction="column"
                      alignItems={isMobileView ? "center" : "flex-start"}
                    >
                      <Typography variant="body2" fontWeight={500}>
                        SOC 2 Type II
                      </Typography>
                      <Typography variant="body2">
                        Governance & Controls
                      </Typography>
                    </Flex>
                  </Flex>
                  <Flex
                    gap={2}
                    direction={isMobileView ? "column" : "row"}
                    alignItems="center"
                  >
                    <img src={IconGdpr} width={72} />
                    <Flex
                      direction="column"
                      alignItems={isMobileView ? "center" : "flex-start"}
                    >
                      <Typography variant="body2" fontWeight={500}>
                        GDPR & CCPA
                      </Typography>
                      <Typography variant="body2">Compliant</Typography>
                    </Flex>
                  </Flex>
                  <Flex
                    gap={2}
                    direction={isMobileView ? "column" : "row"}
                    alignItems="center"
                  >
                    <img src={IconAes} width={72} />
                    <Flex
                      direction="column"
                      alignItems={isMobileView ? "center" : "flex-start"}
                    >
                      <Typography variant="body2" fontWeight={500}>
                        E2E encryption
                      </Typography>
                      <Typography variant="body2">AES-256 bit</Typography>
                    </Flex>
                  </Flex>
                </Flex>
              </Box>

              <Box
                {...(isMobileView
                  ? { width: "100%" }
                  : {
                      backgroundColor: ({ theme }) => theme.mosaiq.colors.white,
                      borderRadius: 5,
                      padding: 8,
                      width: "70%",
                      height: 50,
                    })}
              >
                <Flex
                  direction="column"
                  gap={4}
                  justify="center"
                  style={{ height: "100%" }}
                >
                  <Flex direction="column" gap={4}>
                    <HighlightTypography
                      variant="title2"
                      fontWeight={300}
                      style={{ letterSpacing: -1, lineHeight: "56px" }}
                    >
                      EternalTrust: Empowering your nominee(s)
                    </HighlightTypography>
                    <Flex direction="column" gap={2}>
                      <HighlightTypography
                        variant={isMobileView ? "title5" : "title4"}
                        color="body"
                        fontWeight={300}
                      >
                        In the unfortunate event of your passing, we will
                        securely deliver your documents and messages to your
                        nominee.
                      </HighlightTypography>
                      <HighlightTypography
                        variant={isMobileView ? "title5" : "title4"}
                        color="body"
                        fontWeight={400}
                      >
                        Imagine a digital locker that{" "}
                        <mark className="highlight black">your family</mark>{" "}
                        will receive containing all the important information
                        you've left behind for them.
                      </HighlightTypography>
                      <Typography
                        variant={isMobileView ? "title5" : "title4"}
                        color="body"
                        fontWeight={300}
                      >
                        Nobody, not even we can open them because only you or
                        your nominee has the key.
                      </Typography>
                    </Flex>
                  </Flex>
                </Flex>
              </Box>
            </Flex>
          </Flex>
        </ContentBox>
      </Box>

      <Box
        backgroundColor={({ theme }) => theme.mosaiq.colors.grey40}
        padding={isMobileView ? [16, 0] : [30, 0]}
        marginTop={isMobileView ? 0 : -8}
      >
        <ContentBox>
          <Flex direction="column" gap={4} padding={isMobileView ? 3 : [5, 30]}>
            <Flex direction={"column"} gap={2}>
              <Typography
                variant={isMobileView ? "title2" : "title1"}
                fontWeight={400}
                padding={[0, 2]}
                textAlign="center"
                style={
                  isMobileView
                    ? { lineHeight: "56px", letterSpacing: -1 }
                    : { letterSpacing: -1 }
                }
              >
                Intrigued? {isMobileView && <br />} Get Early Access
              </Typography>
              <Typography
                variant={isMobileView ? "body1" : "title4"}
                fontWeight={300}
                textAlign="center"
                color={isMobileView ? "body" : "passive"}
              >
                Secure your guaranteed early spot on the front by joining
                waitlist today, accompanied by exclusive benefits and
                privileges. Ensure eternal peace of mind.
              </Typography>
            </Flex>
            <Box padding={isMobileView ? 0 : [0, 20]}>
              <WaitlistForm />
            </Box>
          </Flex>
        </ContentBox>
      </Box>

      <HowItWorks />
      <UserStories />
      <Footer />
    </HomeWrapper>
  );
};

export default Home;
