import React, {
  Children,
  cloneElement,
  isValidElement,
  ReactElement,
  useCallback,
  useEffect,
  useRef,
  useState,
  MouseEvent,
  ReactNode,
} from "react";
import Tippy from "@tippyjs/react/headless";

import { mosaiqStyled } from "../../lib";

import { ListItem } from "../ListItem";

import { MenuCard, MenuContainer } from "../Menu";

import { ListPopoverProps } from "./types";

import {
  ListPopoverOpener,
  ListHeader,
  ListFooter,
} from "./ListPopover.styles";

const MENU_ID = "list-popover-items";

const ListPopover = mosaiqStyled<ListPopoverProps, HTMLDivElement>(
  ({
    children,
    header,
    footer,
    innerRef,
    disabled = false,
    opener,
    onOpen,
    onClose,
    maxWidth,
    maxHeight = 304,
    minWidth,
    sameWidth = false,
    offset,
    appendTo = "parent",
    closeOnSelect = true,
    defaultOpen = false,
    ...props
  }) => {
    const [isOpen, setIsOpen] = useState(defaultOpen);

    const [activeItemIndex, setActiveItemIndex] = useState(0);

    const menuRef = useRef<HTMLDivElement>(null);

    const childs = Children.toArray(children);

    let listItemsCounter = 0;

    const handleOpen = () => {
      onOpen?.();
      setIsOpen(true);
    };

    const handleClose = () => {
      onClose?.();
      setIsOpen(false);
    };

    const getActiveItem = () =>
      menuRef?.current?.querySelector("[aria-selected=true]") as HTMLElement;

    const scrollToActiveItem = () => {
      const activeItem = getActiveItem();
      if (activeItem) {
        activeItem.scrollIntoView({
          behavior: "smooth",
          block: "nearest",
        });
      }
    };

    const onKeyDown = (event: React.KeyboardEvent) => {
      switch (event.key) {
        case "Esc":
        case "Escape": {
          event.preventDefault();
          event.stopPropagation();

          handleClose();
          break;
        }

        case "Enter": {
          event.preventDefault();
          event.stopPropagation();

          const activeItem = getActiveItem();
          if (isOpen && activeItem) {
            activeItem.click();
          }

          break;
        }

        case "Down":
        case "ArrowDown": {
          event.preventDefault();
          event.stopPropagation();

          const newItemIndex =
            activeItemIndex < 0 || activeItemIndex === listItemsCounter - 1
              ? 0
              : activeItemIndex + 1;
          setActiveItemIndex(newItemIndex);

          break;
        }

        case "Up":
        case "ArrowUp": {
          event.preventDefault();
          event.stopPropagation();

          const newItemIndex =
            activeItemIndex <= 0 ? listItemsCounter - 1 : activeItemIndex - 1;
          setActiveItemIndex(newItemIndex);

          break;
        }
      }
    };

    const enhanceListItem = useCallback(
      (child: ReactElement, index: number) =>
        cloneElement(child, {
          tabIndex: -1,
          role: "option",
          active: index === activeItemIndex,
          "aria-selected": index === activeItemIndex,
          onMouseEnter: () => setActiveItemIndex(index),
          onClick: (e: MouseEvent<HTMLDivElement>) => {
            child.props?.onClick?.(e);

            if (closeOnSelect) {
              handleClose();
            }
          },
        }),
      [activeItemIndex]
    );

    const enhanceNonListItem = useCallback(
      (child: ReactElement) =>
        cloneElement(child, {
          onMouseEnter: () => setActiveItemIndex(-1),
        }),
      [activeItemIndex]
    );

    useEffect(() => {
      scrollToActiveItem();
    }, [activeItemIndex]);

    useEffect(() => {
      if (disabled) {
        handleClose();
      }
    }, [disabled]);

    useEffect(() => {
      if (defaultOpen) {
        onOpen?.();
      }
    }, []);

    return (
      <div ref={innerRef} onKeyDown={onKeyDown}>
        <Tippy
          appendTo={appendTo}
          interactive
          visible={isOpen}
          placement="bottom-start"
          offset={offset ?? [0, 8]}
          maxWidth="none"
          popperOptions={{
            modifiers: [
              { name: "arrow", enabled: false },
              {
                name: "sameWidth",
                enabled: sameWidth,
                fn: ({ state }) => {
                  // @ts-ignore
                  state.styles.popper.width = `${state.rects.reference.width}px`;
                },
                phase: "beforeWrite",
                requires: ["computeStyles"],
              },
            ],
          }}
          onClickOutside={handleClose}
          render={(attrs) =>
            !!header || childs.length > 0 ? (
              <MenuContainer
                maxWidth={maxWidth}
                maxHeight={maxHeight}
                minWidth={minWidth}
                hasShimmer={false}
                ref={menuRef}
                {...attrs}
              >
                <MenuCard
                  id={MENU_ID}
                  role="listbox"
                  onMouseLeave={() => setActiveItemIndex(-1)}
                >
                  {header && <ListHeader>{header}</ListHeader>}
                  {childs.map((child) =>
                    isValidElement(child) && child.type === ListItem
                      ? enhanceListItem(child, listItemsCounter++)
                      : isValidElement(child)
                        ? cloneElement(child as ReactElement, {
                            children: Children.toArray(
                              (child as ReactElement<{ children: ReactNode }>)
                                .props.children
                            ).map((nestedChild) =>
                              isValidElement(nestedChild) &&
                              nestedChild.type === ListItem
                                ? enhanceListItem(
                                    nestedChild,
                                    listItemsCounter++
                                  )
                                : nestedChild
                            ),
                          })
                        : isValidElement(child)
                          ? enhanceNonListItem(child)
                          : child
                  )}
                  {footer && <ListFooter>{footer}</ListFooter>}
                </MenuCard>
              </MenuContainer>
            ) : undefined
          }
        >
          <ListPopoverOpener
            {...props}
            onClick={
              !disabled
                ? (e) => {
                    e.stopPropagation();

                    if (isOpen) {
                      if (closeOnSelect) handleClose();
                    } else {
                      handleOpen();
                    }
                  }
                : undefined
            }
          >
            {opener({
              isOpen,
              open: handleOpen,
              close: handleClose,
            })}
          </ListPopoverOpener>
        </Tippy>
      </div>
    );
  }
);

ListPopover.displayName = "ListPopover";

export default ListPopover;
