import * as React from 'react';

import { mosaiqStyled } from '../../lib';
import { StyledFunction } from '../../theme';

import { Flex, FlexProps } from '../../base/Flex';

import { SpinnerIcon } from './Spinner.styles';

export interface SpinnerProps extends Omit<FlexProps, 'color'> {
  size?: number;
  color?: string | StyledFunction<string>;
}

const Spinner = mosaiqStyled<SpinnerProps>(
  ({ size = 5, color = ({ theme }) => theme.colors.black, ...props }): JSX.Element => {
    return (
      <Flex alignItems="center" justify="center" {...props}>
        <SpinnerIcon $size={size} $color={color} />
      </Flex>
    );
  },
);

Spinner.displayName = 'Spinner';

export default Spinner;
