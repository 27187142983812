import React from 'react';

import { mosaiqStyled } from '../../lib';

import { Typography } from '../Typography';

import { Label } from '../Label';

import { InputProps } from './types';
import { generateId } from '../../utils';

import { InputField, InputContainer } from './Input.styles';

const Input = mosaiqStyled<InputProps, HTMLDivElement, HTMLInputElement>(
  ({
    innerRef,
    label,
    helperText,
    autoSize = false,
    autoFocus = false,
    disabled = false,
    error = false,
    required = false,
    borderless = false,
    fullWidth = false,
    startAdornment,
    endAdornment,
    inputProps = {},
    size,
    ...props
  }) => {
    const [inputId] = React.useState(generateId(label));

    const [focus, setFocus] = React.useState<boolean>(autoFocus);

    return (
      <InputContainer ref={innerRef} fullWidth={fullWidth} {...props}>
        {!!label && (
          <label htmlFor={inputId}>
            <Label color={disabled ? 'placeholder' : 'body'}>
              {label}
              {required && <> *</>}
            </Label>
          </label>
        )}
        <InputField
          readOnly={inputProps?.readOnly}
          disabled={disabled}
          error={error}
          focus={focus}
          autoSize={autoSize}
          borderless={borderless}
          size={size}
          onClick={(e) => (e.target as HTMLDivElement).querySelector('input')?.focus()}
        >
          {startAdornment}
          <input
            {...inputProps}
            id={!!label ? inputId : undefined}
            disabled={disabled}
            autoFocus={autoFocus}
            onFocus={(e) => {
              setFocus(true);
              inputProps?.onFocus?.(e);
            }}
            onBlur={(e) => {
              setFocus(false);
              inputProps?.onBlur?.(e);
            }}
          />
          {endAdornment}
        </InputField>
        {!!helperText && (
          <Typography
            variant="body4"
            color={error ? 'error' : disabled ? 'placeholder' : 'passive'}
          >
            {helperText}
          </Typography>
        )}
      </InputContainer>
    );
  },
);

Input.displayName = 'Input';

export default Input;
