import React from 'react';
import { mosaiqStyled } from '../../lib';

import { Typography, TypographyProps } from '../Typography';

const Label = mosaiqStyled<TypographyProps, HTMLDivElement>(
  ({ children, color = 'title', ...props }) => (
    <Typography forwardedAs="span" variant="body4" fontWeight={500} color={color} {...props}>
      {children}
    </Typography>
  ),
);

export default Label;
