import { initializeApp } from "firebase/app";
import { getAnalytics, logEvent } from "firebase/analytics";
import { getFunctions, httpsCallable } from "firebase/functions";

const app = initializeApp({
  apiKey: "AIzaSyC14JwVCksvvcYR0uau5HHgLzG304k8XNs",
  appId: "1:606996010321:web:4daf5288b8e02785416f70",
  authDomain: "eternaltrust-5cd8d.firebaseapp.com",
  projectId: "eternaltrust-5cd8d",
});

const analytics = getAnalytics();
const functions = getFunctions();
const createEmail = httpsCallable(functions, "createNewEmail");

export { app, analytics, logEvent, functions, createEmail };
