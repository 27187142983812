import React, { useState, useEffect } from 'react';

import { mosaiqStyled } from '../../lib';

import { IconButtonProps } from './types';

import { Tooltip } from '../Tooltip';

import { IconButtonContainer } from './IconButton.styles';

const IconButton = mosaiqStyled<IconButtonProps, HTMLButtonElement>(
  ({ children, tooltip, innerRef, ...props }) => {
    const [isIOS, setIsIOS] = useState<boolean>(false);

    useEffect(() => {
      setIsIOS(/iPhone|iPad|iPod/.test(navigator.platform));
    }, []);

    if (isIOS) {
      return (
        <IconButtonContainer ref={innerRef} type="button" {...props}>
          {children}
        </IconButtonContainer>
      );
    }

    return (
      <Tooltip
        opener={() => (
          <IconButtonContainer ref={innerRef} type="button" {...props}>
            {children}
          </IconButtonContainer>
        )}
        size="small"
      >
        {tooltip}
      </Tooltip>
    );
  },
);

IconButton.displayName = 'IconButton';

export default IconButton;
