import styled, { css } from 'styled-components';

import { buttonBaseStyles } from '../Button';

import { FabProps } from './types';

export const FabContainer = styled.button<FabProps>`
  ${buttonBaseStyles}
  justify-content: center;
  min-width: ${({ theme }) => theme.mosaiq.spacing(6)}px;
  min-height: ${({ theme }) => theme.mosaiq.spacing(6)}px;
  max-width: ${({ theme }) => theme.mosaiq.spacing(6)}px;
  max-height: ${({ theme }) => theme.mosaiq.spacing(6)}px;
  border-radius: 50%;

  svg {
    min-width: ${({ theme }) => theme.mosaiq.spacing(3)}px;
    min-height: ${({ theme }) => theme.mosaiq.spacing(3)}px;
    max-width: ${({ theme }) => theme.mosaiq.spacing(3)}px;
    max-height: ${({ theme }) => theme.mosaiq.spacing(3)}px;
  }

  ${({ theme, disabled, active }) =>
    disabled
      ? css`
          pointer-events: none;
          background-color: ${theme.mosaiq.colors.grey20};

          svg {
            color: ${theme.mosaiq.colors.placeholder};
          }
        `
      : active
      ? css`
          background-color: ${theme.mosaiq.colors.black};
          box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.05), 0px 5px 10px rgba(0, 0, 0, 0.05),
            0px 2px 4px rgba(0, 0, 0, 0.05), 0px 10px 20px rgba(0, 0, 0, 0.05);

          svg {
            color: ${theme.mosaiq.colors.white};
          }
        `
      : css`
          background-color: ${theme.mosaiq.colors.action};
          box-shadow: 0px 5px 10px rgba(0, 87, 255, 0.1), 0px 2px 4px rgba(0, 87, 255, 0.15);
          transition: 0.2s opacity ease-in-out, 0.2s background-color ease-in-out;

          svg {
            color: ${theme.mosaiq.colors.white};
          }

          &:hover {
            opacity: 0.75;
          }

          &:active {
            opacity: 1;
            background-color: ${theme.mosaiq.colors.black};
            box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.05), 0px 5px 10px rgba(0, 0, 0, 0.05),
              0px 2px 4px rgba(0, 0, 0, 0.05), 0px 10px 20px rgba(0, 0, 0, 0.05);
          }
        `}
`;
