import { FC, ForwardedRef, MouseEventHandler } from "react";
import styled, { css, DefaultTheme, StyledComponent } from "styled-components";

export type CssLengths =
  | number
  | string
  | [number]
  | [string]
  | [number, number]
  | [string, string]
  | [number, number, number]
  | [string, string, string]
  | [number, number, number, number]
  | [string, string, string, string];

/**
 * Provides prop types shared by all `mosaiq-ui` components.
 *
 * @param E is the Element interface corresponding with which DOM element will
 * recieve the `onClick` event handler, and therefore the type of event emitted.
 * Example: `HTMLButtonElement`.
 * @param R is the Element interface corresponding with which DOM element will
 * recieve the `innerRef`, therefore providing a constraint on what can be
 * passed in. By default, this is the same as `E`.
 */
export interface CommonProps<
  E extends Element = Element,
  R extends Element = E,
> {
  margin?: CssLengths;
  marginTop?: string | number;
  marginBottom?: string | number;
  marginLeft?: string | number;
  marginRight?: string | number;
  padding?: CssLengths;
  paddingTop?: string | number;
  paddingBottom?: string | number;
  paddingLeft?: string | number;
  paddingRight?: string | number;
  elevation?: number;
  innerRef?: ForwardedRef<R>;
  onClick?: MouseEventHandler<E>;
  disabled?: boolean;
}

/**
 * Extends {@link CommonProps} to provide the `theme` object.
 */
export interface CommonStyles<
  E extends Element = Element,
  R extends Element = E,
> extends CommonProps<E, R> {
  theme: DefaultTheme;
}

type SpacingKey = "margin" | "padding";

type SpacingValue = string | number | CssLengths | undefined;

const addSingleSpacing = (
  key: "margin" | "padding",
  value: SpacingValue,
  direction: "top" | "bottom" | "right" | "left",
  theme: DefaultTheme
) => {
  if (!value) {
    return "";
  }

  return css`
    ${`${key}-${direction}: ${
      typeof value === "string"
        ? value
        : `${theme.mosaiq.spacing(value as number)}px`
    };`}
  `;
};

const addMultipleSpacing = (
  key: SpacingKey,
  value: SpacingValue,
  theme: DefaultTheme
) => {
  if (typeof value === "number") {
    return css`
      ${`${key}: ${theme.mosaiq.spacing(value)}px;`}
    `;
  }

  if (typeof value === "string") {
    return css`
      ${`${key}: ${value}px;`}
    `;
  }

  if (Array.isArray(value)) {
    return css`
      ${`${key}: ${value
        .map((unit: number | string) =>
          typeof unit === "string" ? unit : `${theme.mosaiq.spacing(unit)}px`
        )
        .join(" ")};`}
    `;
  }

  return "";
};

const commonStyles = <E extends Element = Element, R extends Element = E>({
  margin,
  marginTop,
  marginBottom,
  marginLeft,
  marginRight,
  padding,
  paddingTop,
  paddingBottom,
  paddingLeft,
  paddingRight,
  elevation,
  theme,
  onClick,
  disabled,
}: CommonStyles<E, R>) => {
  let commonStyles = css``;

  if (
    !!margin ||
    !!marginTop ||
    !!marginBottom ||
    !!marginLeft ||
    !!marginRight
  ) {
    commonStyles = css`
      ${addMultipleSpacing("margin", margin, theme)}
      ${addSingleSpacing("margin", marginTop, "top", theme)}
      ${addSingleSpacing("margin", marginBottom, "bottom", theme)}
      ${addSingleSpacing("margin", marginLeft, "left", theme)}
      ${addSingleSpacing("margin", marginRight, "right", theme)}
    `;
  }

  if (
    !!padding ||
    !!paddingTop ||
    !!paddingBottom ||
    !!paddingLeft ||
    !!paddingRight
  ) {
    commonStyles = css`
      ${commonStyles}
      ${addMultipleSpacing("padding", padding, theme)}
      ${addSingleSpacing("padding", paddingTop, "top", theme)}
      ${addSingleSpacing("padding", paddingBottom, "bottom", theme)}
      ${addSingleSpacing("padding", paddingLeft, "left", theme)}
      ${addSingleSpacing("padding", paddingRight, "right", theme)}
    `;
  }

  if (
    typeof elevation === "number" &&
    !!(theme.mosaiq.elevation as any)[elevation]
  ) {
    commonStyles = css`
      ${commonStyles}
      box-shadow: ${(theme.mosaiq.elevation as any)[elevation]};
    `;
  }

  if (typeof onClick === "function" && !disabled) {
    commonStyles = css`
      ${commonStyles}
      cursor: pointer;
    `;
  }

  if (disabled) {
    commonStyles = css`
      ${commonStyles}
      cursor: default;
    `;
  }

  return commonStyles;
};

/**
 * The final merged props interface for a `mosaiq-ui` component, including
 * {@link CommonProps} and their associated types. Use this type if you need
 * to reference the complete props of a component from the outside world, E.G.
 * when forwarding all props from a wrapping component.
 *
 * @param P is the explicitly-declared props interface of the component. If the
 * interface should include attributes like `data-*` and `aria-*`, this type
 * should extend the attributes interface of the element.
 * @param E is the Element interface corresponding with which DOM element will
 * recieve the `onClick` event handler, and therefore the type of event emitted.
 * Example: `HTMLButtonElement`.
 * @param R is the Element interface corresponding with which DOM element will
 * recieve the `innerRef`, therefore providing a constraint on what can be
 * passed in. By default, this is the same as `E`.
 */
export type MosaiqComponentProps<
  P,
  E extends Element = Element,
  R extends Element = E,
> = CommonProps<E, R> & P;

/**
 * The final type of a `mosaiq-ui` component, including its merged props.
 */
export type MosaiqComponent<
  P,
  E extends Element = Element,
  R extends Element = E,
> = StyledComponent<
  FC<MosaiqComponentProps<P, E, R>>,
  DefaultTheme,
  MosaiqComponentProps<P, E, R>,
  never
>;

/**
 * Mosaiq component helper factory, which handles applying styles from
 * {@link CommonProps}, merging type declarations, and constraining props
 * based on type parameters.
 *
 * @param P is the explicitly-declared props interface of the component. If the
 * interface should include attributes like `data-*` and `aria-*`, this type
 * should extend the attributes interface of the element.
 * @param E is the Element interface corresponding with which DOM element will
 * recieve the `onClick` event handler, and therefore the type of event emitted.
 * Example: `HTMLButtonElement`.
 * @param R is the Element interface corresponding with which DOM element will
 * recieve the `innerRef`, therefore providing a constraint on what can be
 * passed in. By default, this is the same as `E`.
 *
 * @param Component is a function component accepting props of type
 * `P & ComponentProps<E, R>`.
 */
export default <P, E extends Element = Element, R extends Element = E>(
  Component: FC<MosaiqComponentProps<P, E, R>>
): StyledComponent<
  FC<MosaiqComponentProps<P, E, R>>,
  DefaultTheme,
  MosaiqComponentProps<P, E, R>,
  never
> => styled(Component)<MosaiqComponentProps<P, E, R>>`
  ${(props) => commonStyles<E, R>(props)}
`;
