import { Box, Flex, Typography } from "@/components/ui";

import ETLogo from "@/assets/logos/et_logo.webp";

import WaitlistForm from "@/components/forms/WaitlistForm";
import { AppTypographyLogo } from "../Navigation/Navigations.styles";
import { useIsExtraSmallOnly } from "@/hooks/useMediaQueries";

import { FooterContainer, FooterWrapper } from "./Footer.styles";

const Footer = () => {
  const isMobileView = useIsExtraSmallOnly();

  return (
    <FooterContainer>
      <FooterWrapper
        direction="column"
        padding={isMobileView ? [20, 3] : [20, 30, 20]}
        gap={8}
      >
        <Flex direction={"column"} gap={2}>
          <Typography
            variant={isMobileView ? "title2" : "title1"}
            fontWeight={400}
            padding={[0, 2]}
            style={{ letterSpacing: -1 }}
          >
            Get Early Access
          </Typography>
          <Typography
            variant={isMobileView ? "body1" : "title4"}
            fontWeight={300}
            color={isMobileView ? "body" : "passive"}
          >
            Secure your guaranteed early spot on the front by joining waitlist
            today, accompanied by exclusive benefits and privileges. Ensure
            eternal peace of mind.
          </Typography>
        </Flex>

        <Box padding={isMobileView ? 0 : [0, 20]}>
          <WaitlistForm customInputClassName="footerInput" />
        </Box>
      </FooterWrapper>

      <Box backgroundColor={({ theme }) => theme.mosaiq.colors.black}>
        <FooterWrapper direction="column" padding={[5, 0, 8]}>
          <Flex
            direction="column"
            alignItems={isMobileView ? "center" : "flex-start"}
            gap={5}
          >
            <Flex justify="space-between">
              <Flex
                direction="column"
                alignItems={isMobileView ? "center" : "flex-start"}
                gap={1}
              >
                <Box width={16} height={16} marginLeft={isMobileView ? 0 : -4}>
                  <img src={ETLogo} alt="eternal trust logo" />
                </Box>

                <AppTypographyLogo
                  variant="title3"
                  color="white"
                  fontWeight={500}
                  textAlign="left"
                >
                  EternalTrust.co
                </AppTypographyLogo>
                <Box width={40}>
                  <Typography
                    variant="body1"
                    color="placeholder"
                    textAlign={isMobileView ? "center" : "left"}
                  >
                    Leave behind a digital locker for your family and
                    confidentially pass on Personal and Financial details
                  </Typography>
                </Box>
              </Flex>
            </Flex>

            <Flex
              direction={isMobileView ? "column" : "row"}
              gap={isMobileView ? 4 : 0}
              justify="space-between"
              alignItems={isMobileView ? "center" : "flex-end"}
              style={!isMobileView ? { width: "100%" } : {}}
            >
              <Flex
                gap={2}
                direction="column"
                alignItems={isMobileView ? "center" : "flex-start"}
              >
                <Typography
                  variant={"title5"}
                  color="white"
                  link
                  style={{ letterSpacing: 1 }}
                  onClick={() => {
                    window.location.href = "mailto:hello@eternaltrust.co";
                  }}
                >
                  hello@eternaltrust.co
                </Typography>
                <Flex
                  gap={0.25}
                  direction="column"
                  alignItems={isMobileView ? "center" : "flex-start"}
                >
                  <Typography variant={"title6"} fontWeight={300} color="white">
                    650 California St floor 7,
                  </Typography>
                  <Typography variant={"title6"} fontWeight={300} color="white">
                    San Francisco, CA 94108
                  </Typography>
                </Flex>
              </Flex>
              <Typography variant="body3" fontWeight={300} color="white">
                ⓒ Copyright 2024 EternalTrust
              </Typography>
            </Flex>
          </Flex>
        </FooterWrapper>
      </Box>
    </FooterContainer>
  );
};

export default Footer;
