import React, { useState } from 'react';

import { mosaiqStyled } from '../../lib';

import { DropdownArrow } from '../../base';

import { ListItem } from '../ListItem';

import { ListGroupProps } from './types';

import { ListGroupContent } from './ListGroup.styles';
import { generateId } from '../../utils';

const ListGroup = mosaiqStyled<ListGroupProps>(
  ({ label, labelProps, open = true, collapsible = true, children, ...props }) => {
    const [isOpen, setIsOpen] = useState(open);

    const [labelId] = useState(generateId(label));

    return (
      <div role="group" aria-labelledby={labelId} {...props}>
        {!!label && (
          <ListItem
            size="regular"
            primaryText={label}
            primaryTextProps={{
              textTransform: 'uppercase',
              variant: 'title7',
              color: 'passive',
            }}
            secondaryAction={collapsible ? <DropdownArrow heading open={isOpen} /> : undefined}
            onClick={collapsible ? () => setIsOpen(!isOpen) : undefined}
            id={labelId}
            {...labelProps}
          />
        )}
        <ListGroupContent isOpen={isOpen} aria-expanded={isOpen}>
          {children}
        </ListGroupContent>
      </div>
    );
  },
);

ListGroup.displayName = 'ListGroup';

export default ListGroup;
