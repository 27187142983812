import { css } from 'styled-components';

export interface TypographyProps {
  fontWeight?: number;
  singleLine?: boolean;
}

export default {
  title1: (props?: TypographyProps) => css`
    font-family: ${({ theme }) => theme.mosaiq.fonts.main};
    font-weight: ${props?.fontWeight || 400};
    font-size: 56px;
    line-height: 64px;
  `,
  title2: (props?: TypographyProps) => css`
    font-family: ${({ theme }) => theme.mosaiq.fonts.main};
    font-weight: ${props?.fontWeight || 400};
    font-size: 48px;
    line-height: 56px;
  `,
  title3: (props?: TypographyProps) => css`
    font-family: ${({ theme }) => theme.mosaiq.fonts.main};
    font-weight: ${props?.fontWeight || 400};
    font-size: 32px;
    line-height: 40px;
  `,
  title4: (props?: TypographyProps) => css`
    font-family: ${({ theme }) => theme.mosaiq.fonts.main};
    font-weight: ${props?.fontWeight || 400};
    font-size: 24px;
    line-height: 32px;
  `,
  title5: (props?: TypographyProps) => css`
    font-family: ${({ theme }) => theme.mosaiq.fonts.main};
    font-weight: ${props?.fontWeight || 400};
    font-size: 20px;
    line-height: 24px;
  `,
  title6: (props?: TypographyProps) => css`
    font-family: ${({ theme }) => theme.mosaiq.fonts.main};
    font-weight: ${props?.fontWeight || 400};
    font-size: 16px;
    line-height: 20px;
  `,
  title7: (props?: TypographyProps) => css`
    font-family: ${({ theme }) => theme.mosaiq.fonts.main};
    font-weight: ${props?.fontWeight || 400};
    font-size: 12px;
    line-height: 16px;
  `,
  body1: (props?: TypographyProps) => css`
    font-family: ${({ theme }) => theme.mosaiq.fonts.main};
    font-weight: ${props?.fontWeight || 400};
    font-size: 18px;
    line-height: ${props?.singleLine ? 22 : 28}px;
  `,
  body2: (props?: TypographyProps) => css`
    font-family: ${({ theme }) => theme.mosaiq.fonts.main};
    font-weight: ${props?.fontWeight || 400};
    font-size: 16px;
    line-height: ${props?.singleLine ? 20 : 24}px;
  `,
  body3: (props?: TypographyProps) => css`
    font-family: ${({ theme }) => theme.mosaiq.fonts.main};
    font-weight: ${props?.fontWeight || 400};
    font-size: 14px;
    line-height: ${props?.singleLine ? 18 : 24}px;
  `,
  body4: (props?: TypographyProps) => css`
    font-family: ${({ theme }) => theme.mosaiq.fonts.main};
    font-weight: ${props?.fontWeight || 400};
    font-size: 12px;
    line-height: ${props?.singleLine ? 16 : 20}px;
  `,
  body5: (props?: TypographyProps) => css`
    font-family: ${({ theme }) => theme.mosaiq.fonts.main};
    font-weight: ${props?.fontWeight || 400};
    font-size: 10px;
    line-height: ${props?.singleLine ? 12 : 14}px;
  `,
};
