import React from 'react';

import { mosaiqStyled } from '../../lib';
import { Typography } from '../Typography';

import { SelectionPillProps } from './types';
import { SelectionPillContainer } from './SelectionPill.styles';

const SelectionPill = mosaiqStyled<SelectionPillProps>(
  ({ selected = false, startAdornment, children, ...props }) => {
    return (
      <SelectionPillContainer role="button" selected={selected} {...props}>
        {startAdornment}
        <Typography variant="title5" ellipsis={{ lines: 1 }} padding={[0, 1]}>
          {children}
        </Typography>
      </SelectionPillContainer>
    );
  },
);

SelectionPill.displayName = 'SelectionPill';

export default SelectionPill;
